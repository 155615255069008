import { EntityFilter } from '@backstage/plugin-catalog-react'
import { Entity } from '@backstage/catalog-model'

export type EntityArchivalStateValue = 'archived' | 'non-archived'

export class EntityArchivalStateFilter implements EntityFilter {
  constructor(readonly value?: EntityArchivalStateValue) {}

  filterEntity(entity: Entity): boolean {
    const isArchived = entity.metadata.annotations?.['gitlab.com/is-archived'] === 'true'
    const value = this.value ? [this.value] : ['archived', 'non-archived']
    if (isArchived && !value.includes('archived')) {
      return false
    }

    if (!isArchived && !value.includes('non-archived')) {
      return false
    }

    return true
  }

  toQueryValue(): string[] {
    return this.value ? [this.value] : []
  }
}
