import React from 'react'
import {
  DefaultEntityFilters,
  EntityAutocompletePicker
} from '@backstage/plugin-catalog-react'
import { EntityRuntimeVersionFilter } from './EntityRuntimeFilter'

type CombinedFilters = DefaultEntityFilters & {
  runtimeVersion: EntityRuntimeVersionFilter
}

export type EntityRuntimePickerProps = {
  showCounts?: boolean
}

export function EntityRuntimeVersionPicker(props: EntityRuntimePickerProps) {
  return (
    <EntityAutocompletePicker<CombinedFilters>
      label="Runtime Version"
      name="runtimeVersion"
      path="metadata.annotations.sennder.com/full-runtime-version"
      Filter={EntityRuntimeVersionFilter}
      showCounts={props.showCounts}
    />
  )
}
