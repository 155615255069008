import {
  createPlugin,
  createRoutableExtension,
  createComponentExtension
} from '@backstage/core-plugin-api'

import { rootRouteRef } from './routes'

export const techInsightsOverviewPlugin = createPlugin({
  id: 'tech-insights-overview',
  routes: {
    root: rootRouteRef
  }
})

export const TechInsightsOverviewPage = techInsightsOverviewPlugin.provide(
  createRoutableExtension({
    name: 'TechInsightsOverviewPage',
    component: () => import('./components/RouterPage').then(m => m.RouterPage),
    mountPoint: rootRouteRef
  })
)

export const EntityTechInsightsOverviewContent = techInsightsOverviewPlugin.provide(
  createComponentExtension({
    name: 'EntityTechInsightsOverviewContent',
    component: {
      lazy: () => import('./components/EntityContent').then(m => m.EntityContent)
    }
  })
)

export const EntityTechInsightsOverviewCard = techInsightsOverviewPlugin.provide(
  createComponentExtension({
    name: 'EntityTechInsightsOverviewCard',
    component: {
      lazy: () => import('./components/EntityCard').then(m => m.EntityCard)
    }
  })
)

export const EntityTechInsightsOverviewPage = techInsightsOverviewPlugin.provide(
  createComponentExtension({
    name: 'EntityTechInsightsOverviewPage',
    component: {
      lazy: () => import('./components/EntityPage').then(m => m.EntityPage)
    }
  })
)

export const EntityTechInsightsOverviewFailedChecksCard =
  techInsightsOverviewPlugin.provide(
    createComponentExtension({
      name: 'EntityTechInsightsOverviewFailedChecksCard',
      component: {
        lazy: () => import('./components/FailedChecksCard').then(m => m.FailedChecksCard)
      }
    })
  )

export const EntityDebugChecksDialog = techInsightsOverviewPlugin.provide(
  createComponentExtension({
    name: 'EntityDebugChecksDialog',
    component: {
      lazy: () =>
        import('./components/EntityDebugChecksDialog').then(
          m => m.EntityDebugChecksDialog
        )
    }
  })
)
