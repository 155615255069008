import React, { useState, useMemo, useEffect } from 'react'
import {
  catalogApiRef,
  useEntityList,
  EntityOwnerFilter,
  EntityOwnerPicker
} from '@backstage/plugin-catalog-react'
import { Entity, stringifyEntityRef, parseEntityRef } from '@backstage/catalog-model'
import { useApi } from '@backstage/core-plugin-api'
import useAsync from 'react-use/lib/useAsync'
import { DefaultEntityFilters } from '@backstage/plugin-catalog-react'
import { Box, TextField, Typography, Popper } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { EntityBaFilter } from './EntityBaFilter'
import { EntityPodFilter } from './EntityPodFilter'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import ExpandMoreIcon from '@material-ui/icons/NavigateNext'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

type CombinedFilters = DefaultEntityFilters & {
  ba?: EntityBaFilter
  pod?: EntityPodFilter
  owners?: EntityOwnerFilter
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

function OptionCheckbox({ selected }: { selected: boolean }) {
  return <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
}

const FullWidthPopper = function (props: any) {
  return (
    <Popper
      {...props}
      style={{
        width: 'fit-content'
      }}
      placement="right-start"
    />
  )
}

function entityLabel(entity: Entity) {
  return entity.metadata.title || entity.metadata.name
}

function sortByLabel(entities: Entity[]) {
  return [...entities].sort((a, b) => entityLabel(a).localeCompare(entityLabel(b)))
}

function renderOption(entity: Entity, { selected }: { selected: boolean }) {
  return (
    <FormControlLabel
      control={<OptionCheckbox selected={selected} />}
      label={entityLabel(entity)}
      onClick={event => event.preventDefault()}
    />
  )
}

function parentRef(entity: Entity) {
  const parentRef = entity.spec?.parent
  if (!parentRef) {
    return undefined
  }

  const parentCompoundRef = parseEntityRef(entity.spec?.parent as any, {
    defaultKind: 'group',
    defaultNamespace: entity.metadata.namespace
  })

  return stringifyEntityRef(parentCompoundRef)
}

export function EntityBaPodPicker() {
  const {
    updateFilters,
    filters,
    queryParameters: { ba: baQueryParameter, pod: podQueryParameter }
  } = useEntityList<CombinedFilters>()

  const catalogApi = useApi(catalogApiRef)

  const { value: allGroupEntities } = useAsync(
    async () =>
      (
        await catalogApi.getEntities({
          filter: {
            kind: 'Group'
          }
        })
      ).items
  )

  const allBaEntities: Entity[] = sortByLabel(
    allGroupEntities?.filter(entity => entity.spec?.type === 'business-area') ?? []
  )
  const allPodEntities: Entity[] = sortByLabel(
    allGroupEntities?.filter(entity => entity.spec?.type !== 'business-area') ?? []
  )

  const baQueryParameters = useMemo(
    () => [baQueryParameter].flat().filter(Boolean) as string[],
    [baQueryParameter]
  )

  const podQueryParameters = useMemo(
    () => [podQueryParameter].flat().filter(Boolean) as string[],
    [podQueryParameter]
  )

  const [selectedBaRefs, setSelectedBaRefs] = useState(
    baQueryParameters.length
      ? baQueryParameters
      : (filters['ba'] as unknown as { values: string[] })?.values ?? []
  )

  const [selectedPodRefs, setSelectedPodRefs] = useState(
    podQueryParameters.length
      ? podQueryParameters
      : (filters['pod'] as unknown as { values: string[] })?.values ?? []
  )

  // Set selected options on query parameter updates; this happens at initial page load and from
  // external updates to the page location
  useEffect(() => {
    if (baQueryParameters.length) {
      setSelectedBaRefs(baQueryParameters)
    }

    if (podQueryParameters.length) {
      setSelectedPodRefs(podQueryParameters)
    }
  }, [baQueryParameters, podQueryParameters])

  const hasBaFilter = selectedBaRefs.length > 0
  useEffect(() => {
    updateFilters({
      ba: hasBaFilter ? new EntityBaFilter(selectedBaRefs) : undefined
    } as Partial<CombinedFilters>)
  }, [hasBaFilter, selectedBaRefs, updateFilters])

  const hasPodFilter = selectedPodRefs.length > 0
  useEffect(() => {
    updateFilters({
      pod: hasPodFilter ? new EntityPodFilter(selectedPodRefs) : undefined
    } as Partial<CombinedFilters>)
  }, [hasPodFilter, selectedPodRefs, updateFilters])

  const refsToBas = (refs: string[]) =>
    allBaEntities.filter(entity => refs.includes(stringifyEntityRef(entity)))

  const refsToPods = (refs: string[]) =>
    allPodEntities.filter(entity => refs.includes(stringifyEntityRef(entity)))

  const scopedPodEntities = hasBaFilter
    ? allPodEntities.filter(pod => selectedBaRefs.includes(parentRef(pod) as string))
    : allPodEntities

  // We have to fall back to the default messy owner picker if query parameters
  // contain the `filters[owners]`. It might happen if there’s a hyperlink
  // to catalog that expects the default set of filters and not the sennder-specific
  // BA/Pod
  const hasRawOwnerFilter = (filters.owners?.values.length ?? 0) > 0
  const baPodVisible = hasBaFilter || hasPodFilter || !hasRawOwnerFilter

  return (
    <Box pb={1} pt={1}>
      <Box style={{ display: baPodVisible ? 'block' : 'none' }}>
        <Typography variant="button" component="label">
          {'Business Area'}
          <Autocomplete
            multiple
            disableCloseOnSelect
            disableClearable
            options={allBaEntities}
            value={refsToBas(selectedBaRefs)}
            getOptionLabel={entityLabel}
            onChange={(_event: object, entities: Entity[]) =>
              setSelectedBaRefs(entities.map(stringifyEntityRef))
            }
            renderOption={renderOption}
            size="small"
            renderInput={params => <TextField variant="outlined" {...params} />}
            PopperComponent={FullWidthPopper}
            popupIcon={<ExpandMoreIcon />}
          />
        </Typography>
        <Typography variant="button" component="label">
          {'Pod/Team'}
          <Autocomplete
            multiple
            disableCloseOnSelect
            disableClearable
            options={scopedPodEntities}
            value={refsToPods(selectedPodRefs)}
            getOptionLabel={entityLabel}
            onChange={(_event: object, entities: Entity[]) =>
              setSelectedPodRefs(entities.map(stringifyEntityRef))
            }
            renderOption={renderOption}
            size="small"
            renderInput={params => <TextField variant="outlined" {...params} />}
            PopperComponent={FullWidthPopper}
            popupIcon={<ExpandMoreIcon />}
          />
        </Typography>
      </Box>
      <Box style={{ display: hasRawOwnerFilter ? 'block' : 'none' }}>
        <EntityOwnerPicker />
      </Box>
    </Box>
  )
}
