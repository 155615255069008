import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export default (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 79.55 53.03" {...props}>
    <path
      fill="inherit"
      d="M 19.857598,3.9334635 V 0 H 47.74378 V 3.9434635 H 39.850671 V 8.24 h 1.18 H 48.75 c 2.99132,-0.1707877 5.55761,0.1093655 5.74,3.1 0.03416,0.438368 0.104394,0.873174 0.21,1.3 0.240148,1.140227 1.276546,2.98971 2.44,2.925195 h 6.36 c 0.111323,0.04743 0.21838,0.104305 0.32,0.17 v 8.165476 h 7.544488 v -7.872366 c 0.263981,-0.06089 0.531029,-0.107625 0.8,-0.14 H 76.09 c 0.56788,-0.08898 1.093701,0.317954 1.15,0.89 0.58,2.49 1.27,3.651695 1.84,6.091695 0.239876,1.050386 0.37057,2.122747 0.39,3.2 0,3.4 -0.11,6.8 0,10.2 0.08571,3.515907 -0.40409,7.02217 -1.45,10.38 -0.31,1.12 -0.53,-0.912439 -0.87,0.187561 -0.11,0.34 -0.49,0.84 -0.75,0.84 -3,0.06 -1.835512,0 -5.005512,0 V 39.616573 H 63.76 V 47.62 c 0.0018,2.898647 -2.293161,5.277632 -5.19,5.38 H 29.89 c -1.611989,-0.07474 -3.125918,-0.795657 -4.2,-2 -2.1,-2.08 -4.24,-4.13 -6.31,-6.23 -0.673349,-0.751962 -1.652218,-1.156757 -2.66,-1.1 H 15.36 C 12.389969,43.6099 8.033009,41.149884 8.1,38.18 V 31.89 H 3.9944878 v 8.53 C 1.8244878,40.12 0.1479514,39.7 0.1479514,37.76 0.11971144,30.106773 3.220078e-5,22.47451 0,15.49 0.86077349,12.692851 0.90998223,11.924103 3.8944878,11.723464 V 23.899646 H 8.1 V 19.83 17.31 c -0.04523,-2.988575 4.312685,-5.462166 7.3,-5.56 h 1.08 c 2.23,0 0.38,-0.15 1.31,-2.18 0.824052,-2.0779836 2.855209,-1.4227767 5.09,-1.37 h 4.883464 V 3.9034635 Z"
    />
  </SvgIcon>
)
