import { EntityFilter } from '@backstage/plugin-catalog-react'
import { Entity } from '@backstage/catalog-model'

export class EntityPodFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  filterEntity(entity: Entity): boolean {
    const podRef = entity.metadata.annotations?.['sennder.com/pod'] as string | undefined
    return podRef ? this.values.includes(podRef) : false
  }

  toQueryValue(): string[] {
    return this.values
  }
}
