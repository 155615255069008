import { EntityFilter } from '@backstage/plugin-catalog-react'
import { Entity } from '@backstage/catalog-model'

export class EntityRuntimeVersionFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  filterEntity(entity: Entity): boolean {
    const runtimeRef = entity.metadata.annotations?.[
      'sennder.com/full-runtime-version'
    ] as string | undefined
    return runtimeRef ? this.values?.includes(runtimeRef) : false
  }

  toQueryValue(): string[] {
    return this.values
  }
}
