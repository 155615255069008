import React, { useState, useEffect } from 'react'
import { useAsync } from 'react-use'
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react'
import { FieldValidation } from '@rjsf/utils'
import { CircularProgress, FormControl, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useApi, configApiRef, identityApiRef } from '@backstage/core-plugin-api'

const ROOT_GROUP: string = 'sennder'

export function SennderGitlabGroupPicker({
  onChange,
  rawErrors,
  required,
  formData
}: FieldExtensionComponentProps<string>) {
  const [groupValue, setGroupValue] = useState(ROOT_GROUP)
  const [inputValue, setInputValue] = useState('')
  const [defaultValueSet, setDefaultValueSet] = useState(false)

  const configApi = useApi(configApiRef)
  const identityApi = useApi(identityApiRef)
  const backendBaseUrl = configApi.getString('backend.baseUrl')
  let token: string | null = null

  const getToken = async (): Promise<string | null> => {
    if (token) {
      return token
    }

    try {
      const credentials = await identityApi.getCredentials()
      token = credentials?.token || null
      return token
    } catch (error) {
      return null
    }
  }

  const { value: maybeAutocompleteGroups, loading } = useAsync(async () => {
    const apiUrl = `${backendBaseUrl}/api/proxy/gitlabci`
    const url = `${apiUrl}/groups?order_by=similarity&per_page=100&search=${encodeURIComponent(
      inputValue
    )}`
    const authToken = await getToken()
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })

    const groupObjects = await response.json()
    const groups = groupObjects.map((g: any) => g.full_path).sort()
    return groups.includes(ROOT_GROUP) ? groups : [ROOT_GROUP, ...groups]
  }, [inputValue])

  // set default value when groups are loaded
  useEffect(() => {
    if (
      !defaultValueSet &&
      maybeAutocompleteGroups &&
      maybeAutocompleteGroups.length > 0
    ) {
      setGroupValue(maybeAutocompleteGroups[0])
      onChange(maybeAutocompleteGroups[0])
      setDefaultValueSet(true)
    }
  }, [maybeAutocompleteGroups, onChange, defaultValueSet])

  const autocompleteGroups = maybeAutocompleteGroups ?? [ROOT_GROUP]

  const handleChange = (_event: any, group: string | null) => {
    setGroupValue(group ?? '')
    onChange(group ?? '')
  }

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        id="groupName"
        value={groupValue}
        inputValue={inputValue}
        loading={loading}
        onChange={handleChange}
        onInputChange={(_event, text) => setInputValue(text)}
        options={autocompleteGroups}
        autoSelect
        freeSolo
        renderInput={params => (
          <TextField
            {...params}
            label="GitLab Group"
            margin="dense"
            helperText="Group path where the repository will be created (the path after gitlab.com/)"
            FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
            variant="standard"
            required={required}
            disabled={loading}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
    </FormControl>
  )
}

export function validateSennderGitlabGroup(value: string, validation: FieldValidation) {
  if (!/^[a-z0-9-\/]+$/g.test(value) || value === undefined) {
    validation.addError(
      `Should be a valid GitLab group. E.g.: /sennder/engineering-effectiveness/devx/developer-portal`
    )
  }
}
