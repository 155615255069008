import React from 'react'
import {
  DefaultEntityFilters,
  EntityAutocompletePicker
} from '@backstage/plugin-catalog-react'
import { EntityFailedChecksFilter } from './EntityChecksFilter'

type CompbinedFilters = DefaultEntityFilters & {
  failedChecks?: EntityFailedChecksFilter
}

export type EntityFailedChecksPickerProps = {
  showCounts?: boolean
}

export function EntityFailedChecksPicker(props: EntityFailedChecksPickerProps) {
  return (
    <EntityAutocompletePicker<CompbinedFilters>
      label="Failed Checks"
      name="failedChecks"
      path="metadata.techInsightsSnapshot.failedChecks"
      Filter={EntityFailedChecksFilter}
      showCounts={props.showCounts}
    />
  )
}
