import Plausible from 'plausible-tracker'

export function startTracking() {
  const plausible = Plausible({
    domain: 'developers.sennder.tech',
    //trackLocalhost: true,
    apiHost: 'https://developers.sennder.tech/api/proxy/plausible'
  })

  const { enableAutoPageviews } = plausible

  enableAutoPageviews()
}
