import '@backstage/cli/asset-types'
import React from 'react'
// @ts-ignore Could not find a declaration file for module 'react-dom/client'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as Plausible from './plausible'

Plausible.startTracking()

const root = createRoot(document.getElementById('root'))
root.render(<App />)
