import React from 'react'

import Link from '@material-ui/core/Link'
import Container from '@material-ui/core/Container'
import { H1, H2, H3, P, Ul, Li, Code, Strike } from '../typography'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { sortByTitle } from '../../utils'
import bookmarks from '../../references/bookmarks'

import { AboveTheFold } from './AboveTheFold'

//======================================================================
//
// Types
//
//======================================================================

interface IGlossaryEntry {
  title: string
  description: string
  href?: string
}

//======================================================================
//
// Reference content
//
//======================================================================

const portalNavi = [
  {
    title: 'Service Catalog',
    href: '/catalog',
    description: (
      <React.Fragment>
        explore all the components available, filtering by a business area (BA) and a team
        (POD);
      </React.Fragment>
    )
  },
  {
    title: 'APIs',
    href: '/catalog?filters[kind]=api',
    description: (
      <React.Fragment>
        explore all API endpoints available in the system and who provides them;
      </React.Fragment>
    )
  },
  {
    title: 'Academy',
    href: '/docs/engineering-and-data-platform/component/tech-onboarding-docs',
    description: (
      <React.Fragment>
        slides and text articles of the tech-onboarding Academy sessions;
      </React.Fragment>
    )
  },
  {
    title: 'Docs',
    href: '/docs',
    description: (
      <React.Fragment>
        documentation for individual components, APIs, tutorials, and guides;
      </React.Fragment>
    )
  },
  {
    title: 'Tech Radar',
    href: '/tech-radar',
    description: (
      <React.Fragment>
        pick the proper technology stack for your next task;
      </React.Fragment>
    )
  },
  {
    title: 'DORA4',
    href: '/dora',
    description: (
      <React.Fragment>
        see what’s the company pace in development and delivery;
      </React.Fragment>
    )
  },
  {
    title: 'Opsgenie',
    href: '/opsgenie',
    description: (
      <React.Fragment>
        fire? Call <Strike>911</Strike> mate who is on duty today for help;
      </React.Fragment>
    )
  }
]

const gitlabLinks = {
  editThisPage:
    'https://gitlab.com/sennder/engineering-effectiveness/devx/developer-portal/-/edit/main/packages/app/src/components/home/HomePage.tsx',
  newIssue:
    'https://gitlab.com/sennder/engineering-effectiveness/devx/developer-portal/-/issues/new'
}

const glossary: IGlossaryEntry[] = sortByTitle([
  {
    title: 'BA',
    description: 'Business Area; a high-level organizational division;'
  },
  {
    title: 'Microfrontend',
    description:
      'like a microservice but for UI; it can be a page, a widget, a pane, etc bound to relevant backend API;',
    href: 'https://sennder.atlassian.net/wiki/spaces/PLE/pages/2681110606/Microfrontends'
  },
  {
    title: 'Mexit',
    description:
      'the ongoing process to get rid of Mothership, splitting it into better manageable parts;',
    href: 'https://miro.com/app/board/uXjVOOAcU68=/?share_link_id=882846116748'
  },
  {
    title: 'Mothership',
    description:
      'a big monolith, v1 of sennder software, now considered legacy and continuously decomposed into microservices;'
  },
  {
    title: 'M&R',
    description: 'Mexit & Replatformization',
    href: 'https://miro.com/app/board/o9J_lsMxcp4=/?moveToWidget=3458764531949838723&cot=14'
  },
  {
    title: 'Octopus',
    description:
      'inside-facing sennder software to match carriers and shippers semi-manually;',
    href: 'https://octopus.sennder.com/'
  },
  {
    title: 'Orcas',
    description:
      'customer-facing sennder application for carriers; that is, customers who have trucks and move loads for money;',
    href: 'https://app.orcas.sennder.com/'
  },
  {
    title: 'Otters',
    description:
      'customer-facing sennder application for shippers; that is, customers who have crates with goods wanting to move them from point A to B;',
    href: 'https://otters.sennder.com/'
  },
  {
    title: 'Pod',
    description: 'a single aspect (subdivision) of BA managed by a single team;'
  },
  {
    title: 'Replatformization',
    description:
      'the ongoing process of rethinking the software domain and aligning it with the actual business domain;'
  },
  {
    title: 'SNDT',
    description:
      'sennder Developer Tools; a CLI-toolchain to create and manage services;',
    href: 'https://gitlab.com/sennder/cross-cutting/sennder-devtools'
  }
])

const toolkit = sortByTitle([
  {
    title: 'GitLab',
    href: 'https://gitlab.com/sennder/',
    purpose:
      'Git platform of choice. Quite similar to GitHub. Also used as a continuous integration platform.',
    status: 'Active'
  },
  {
    title: 'Confluence',
    href: 'https://sennder.atlassian.net/wiki/spaces/PLE/overview?homepageId=1188102495',
    purpose: 'Wiki / Knowledge base',
    status: 'Active'
  },
  {
    title: 'Jira',
    href: 'https://sennder.atlassian.net',
    purpose: 'Project management and issue tracking.',
    status: 'Sunsetting, moving to Azure DevOps in 2023'
  },
  {
    title: 'Datadog',
    href: 'https://app.datadoghq.eu/apm/home?env=prod',
    purpose: 'Production monitoring, log aggregator.',
    status: 'Active'
  },
  {
    title: 'Sentry',
    href: 'https://sentry.io/organizations/sennder-gmbh/projects/',
    purpose: 'Runtime errors analytics.',
    status: 'Active'
  },
  {
    title: 'Opsgenie',
    href: 'https://sennder.app.eu.opsgenie.com/alert/list',
    purpose: 'Incident management and alerting.',
    status: 'Active'
  },
  {
    title: 'Hound',
    href: 'https://hound.cloud.sennder.com/',
    purpose:
      'Search for code using regexes across the whole sennder GitLab (because the built-in GitLab search is poor)',
    status: 'Active'
  }
])

//======================================================================
//
// The page
//
//======================================================================

export function HomePage() {
  return (
    <>
      <AboveTheFold />
      <Container maxWidth="md">
        <H1>Welcome to sennder Developer Portal</H1>
        <P>
          Hey, fellow sennder developer, you’re on the company-wide site dedicated to
          developers of sennder. Here you should find all the answers about the process
          and the current state of development, access tools, get the docs, and so on.
          Think of it as “Yahoo!” for a sennder developer.
        </P>
        <P>
          sennder software comprises hundreds of components and APIs using numerous
          technologies and scattered across hundreds of repos, projects, and code owners:
          enough for a human to get lost. Use Developer Portal to navigate across this
          land.
        </P>
        <Ul>
          {portalNavi.map(({ title, href, description }) => (
            <Li key={title}>
              <Link href={href}> {title} </Link>
              {` — `}
              {description}
            </Li>
          ))}
        </Ul>

        <H2>Newcomer guide</H2>
        <P>
          Here’s a very rough outline of things employed by sennder development. Note it
          is a living organism that moves fast. So, the info below might become outdated.
          If you spotted a mistake in the info, feel free to{' '}
          <Link href={gitlabLinks.editThisPage}>fix it</Link> or at least point it out by{' '}
          <Link href={gitlabLinks.newIssue}>creating an issue</Link>.
        </P>

        <H3>Glossary</H3>
        <P>Terms specific to sennder tech in A-Z order:</P>
        <Ul>
          {glossary.map(({ title, description, href }) => {
            if (href) {
              return (
                <Li key={title}>
                  <Link href={href} target="_blank">
                    {title}
                  </Link>{' '}
                  {` — `} {description}
                </Li>
              )
            }
            return (
              <Li key={title}>
                <b>{title}</b> {` — `} {description}
              </Li>
            )
          })}
        </Ul>
        <P>
          Missing a term? Help improve this page:{' '}
          <Link href={gitlabLinks.newIssue}>open an issue</Link> or{' '}
          <Link href={gitlabLinks.editThisPage}>add it directly</Link>.
        </P>

        <H3>Software toolkit</H3>
        <TableContainer>
          <Table>
            <colgroup>
              <col style={{ width: '20%' }} />
              <col style={{ width: '60%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Tool</TableCell>
                <TableCell>Purpose</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {toolkit.map(row => (
                <TableRow key={row.title}>
                  <TableCell component="th" scope="row">
                    <Link href={row.href}> {row.title} </Link>
                  </TableCell>
                  <TableCell>{row.purpose}</TableCell>
                  <TableCell>{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <P>
          For more information see <Link href="/tech-radar">Tech Radar</Link>.
        </P>

        <H3>Important documents and references</H3>
        <Ul>
          {sortByTitle(bookmarks).map(({ title, href, description }) => (
            <Li key={title}>
              <Link href={href}> {title} </Link>
              {` — `}
              {description}
            </Li>
          ))}
        </Ul>

        <H3>Technologies, languages, and libraries in use</H3>
        <P>
          See the <Link href="/tech-radar">Tech Radar</Link>.
        </P>

        <H3>Develop, get reviewed and deployed</H3>
        <P>
          There’s no strict process for the development cycle at the company level. Each
          team is free to decide how its members should do their work independently. To
          understand a team’s workflow, refer to the corresponding component{' '}
          <Code>README.md</Code> or reach EM.
        </P>
        <P>Nevertheless, some good habits are valid universally within sennder:</P>
        <Ul>
          <Li>
            Create feature branches in Git; do not commit to <Code>main</Code>/
            <Code>master</Code> directly;
          </Li>
          <Li>
            Keep the scope of a commit small and clean: make it easier to understand for
            team-mates;
          </Li>
          <Li>Creating a merge request, ask a team-mate for code review;</Li>
        </Ul>

        <H3>Creating your first component</H3>
        <P>
          Any new component must adhere to some standards and minimal structure to fit
          sennder ecosystem. Luckily, there are tools to make it easy.
        </P>
        <P>
          The process is described in a comprehensive documentation section “
          <Link href="https://sennder.atlassian.net/wiki/spaces/PLE/pages/1609302474/Getting+started+with+sennCloud">
            Getting started with sennCloud
          </Link>
          ”.
        </P>

        <H2>Contributing to Developer Portal</H2>
        <P>
          Have you found an inconsistency, bug, or obsolete information, or want a new
          feature to work more comfortably? The DevX team is here to help. Here are some
          ways to contribute:
        </P>
        <Ul>
          <Li>
            <Link href={gitlabLinks.newIssue}>Open an issue</Link> in the{' '}
            <Code>developer-portal</Code> repo reporting a bug or requesting a feature
          </Li>
          <Li>
            Add a reaction 👍 for an issue, comment on an issue, so that we can better
            prioritize further improvements (remember, <em>you</em> is our customer)
          </Li>
          <Li>
            If you want to improve a paragraph or two in docs, edit the content with
            GitLab web editor and create MR immediately. For example, you can{' '}
            <Link href={gitlabLinks.editThisPage}>edit this page</Link>
          </Li>
        </Ul>
      </Container>
    </>
  )
}
