import React from 'react'
import { humanizeEntityRef, EntityRefLink } from '@backstage/plugin-catalog-react'
import { Typography } from '@material-ui/core'
import { TableColumn, OverflowTooltip } from '@backstage/core-components'
import { Entity } from '@backstage/catalog-model'
import { CatalogTableRow } from '@backstage/plugin-catalog'

function ComboCell(props: { defaultKind: string; entity: Entity }) {
  const { entity, defaultKind } = props

  const description = (
    <Typography variant="caption" component="div">
      <OverflowTooltip text={entity.metadata.description} placement="bottom-start" />
    </Typography>
  )

  return (
    <>
      <EntityRefLink
        entityRef={entity}
        defaultKind={defaultKind}
        title={entity.metadata?.title}
      />
      {description}
      <br />
    </>
  )
}

export function createComboColumn(options?: {
  defaultKind?: string
}): TableColumn<CatalogTableRow> {
  function formatContent(entity: Entity): string {
    return (
      entity.metadata?.title ||
      humanizeEntityRef(entity, {
        defaultKind: options?.defaultKind
      })
    )
  }

  const defaultKind = options?.defaultKind || 'Component'
  return {
    title: options?.defaultKind || 'Component',
    field: 'resolved.name',
    highlight: true,
    width: 'auto',
    cellStyle: {
      minWidth: '30em'
    },
    customSort({ entity: entity1 }, { entity: entity2 }) {
      return formatContent(entity1).localeCompare(formatContent(entity2))
    },
    render: ({ entity }) => <ComboCell entity={entity} defaultKind={defaultKind} />
  }
}
