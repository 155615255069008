import React, { PropsWithChildren } from 'react'

import Typography from '@material-ui/core/Typography'

export function H1({ children }: PropsWithChildren<{}>) {
  return (
    <Typography variant="h1" style={{ marginTop: '4rem' }}>
      {children}
    </Typography>
  )
}

export function H2({ children }: PropsWithChildren<{}>) {
  return (
    <Typography variant="h2" style={{ marginTop: '4rem' }}>
      {children}
    </Typography>
  )
}

export function H3({ children }: PropsWithChildren<{}>) {
  return (
    <Typography variant="h3" style={{ marginTop: '2rem' }}>
      {children}
    </Typography>
  )
}

export function P({ children }: PropsWithChildren<{}>) {
  return (
    <Typography variant="body1" paragraph={true} style={{ maxWidth: '70ch' }}>
      {children}
    </Typography>
  )
}

export function Ul({ children }: PropsWithChildren<{}>) {
  return <ul style={{ maxWidth: '70ch' }}>{children}</ul>
}

export function Li({ children }: PropsWithChildren<{}>) {
  return (
    <Typography variant="body1" paragraph={true} component="li">
      {children}
    </Typography>
  )
}

export function Strike({ children }: PropsWithChildren<{}>) {
  return <span style={{ textDecoration: 'line-through' }}>{children}</span>
}

export function Code({ children }: PropsWithChildren<{}>) {
  return (
    <code style={{ backgroundColor: '#fffaee', padding: '0 0.5ex' }}>{children}</code>
  )
}
