import { createPlugin, useApi } from '@backstage/core-plugin-api'
import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
  // TechDocsMetadata,
  techdocsStorageApiRef
  // useTechDocsReaderPage,
} from '@backstage/plugin-techdocs-react'
import { useEffect } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router'
import { AsyncState } from 'react-use/lib/useAsyncFn'
import { useAsyncRetry } from 'react-use'
import { CompoundEntityRef } from '@backstage/catalog-model'
const techdocsRedirectPlugin = createPlugin({
  id: 'techdocsRedirect'
})
// strongly inspired from https://github.com/adamjarret/parse-refresh-redirect/blob/master/index.js
import { Parser } from 'htmlparser2'

const pattern = /\d*;?\s*url=(.+)/i

function parseMetaRefreshContent(content: string): string | undefined {
  const matches = content.match(pattern)
  const url = matches ? matches[1] : undefined
  return url
}

function parseRefresh(text: string) {
  const resultHolder: Record<string, string | undefined> = {}
  const metaParser = buildMetaParser(resultHolder)
  metaParser.parseComplete(text)
  return resultHolder['redirect']
}

function buildMetaParser(resultHolder: Record<string, string | undefined>) {
  return new Parser({
    onopentag(name, attributes) {
      if (name === 'meta' && attributes['http-equiv'] === 'refresh') {
        const redirect = parseMetaRefreshContent(attributes['content'])
        resultHolder['redirect'] = redirect
      }
    }
  })
}

function resolveRelPath(basePath: string, relativePath: string) {
  if (relativePath.startsWith('/')) {
    return relativePath
  } else if (!relativePath.includes('../')) {
    // we do not have ../ items
    if (basePath.endsWith('/')) {
      return basePath + relativePath
    } else {
      const lastSlash = basePath.lastIndexOf('/')
      const basedir = basePath.substring(0, lastSlash)
      return `${basedir}/${relativePath}`
    }
  } else {
    const countOfDotdotslash = relativePath.split('../').length - 1
    const pathSplit = basePath.split('/')
    const finalPath = pathSplit.slice(0, pathSplit.length - countOfDotdotslash).join('/')
    const rpWithoutDds = relativePath.replace('../', '')
    return `${finalPath}/${rpWithoutDds}`
  }
}

export type RawPage = {
  content: string
  path: string
  entityId: CompoundEntityRef
}

export function useRawPage(
  path: string,
  kind: string,
  namespace: string,
  name: string
): AsyncState<RawPage> & {
  retry(): void
} {
  const techdocsStorageApi = useApi(techdocsStorageApiRef)

  return useAsyncRetry(async () => {
    const content = await techdocsStorageApi.getEntityDocs(
      { kind, namespace, name },
      path
    )

    return {
      content,
      path,
      entityId: {
        kind,
        name,
        namespace
      }
    }
  }, [techdocsStorageApi, kind, namespace, name, path])
}

const TechDocsRedirector = () => {
  const params = useParams()

  const path = params['*'] ?? ''
  const { namespace, name, kind } = params
  const rp = useRawPage(path, kind!, namespace!, name!)
  const navigate = useNavigate()

  useEffect(() => {
    // don't do anything until we are done loading
    if (rp.loading || rp.value === undefined) {
      return
    }
    const { content } = rp.value!
    let r = parseRefresh(content)
    // if there is no redirect to interpret or it's an empty string, do nothing
    if (r === undefined || !r.length) {
      return
    } else {
      // handle external redirects. just these 2 protocols are respected
      if (r.startsWith('http://') || r.startsWith('https://')) {
        window.location.replace(r)
        return
      }
      // handle ../... as path-relative and /abc/def/xyz as route-relative to allow redirecting across projects if need be
      // TODO: react-router v6.4+ only, enable when backstage gets there
      /*
      const relativeMode: RelativeRoutingType = r.startsWith('..')
        ? 'path'
        : 'route';
      navigate(r, {
        relative: relativeMode,
      });
      */
      // in react-router v6.3 we gotta manage this ourselves
      r = resolveRelPath(path, r)
      console.log('replacing ../', r)
      const pathTo = generatePath('/docs/:namespace/:kind/:name/*', {
        namespace: namespace!,
        name: name!,
        kind: kind!,
        '*': r
      })
      console.log('build path:', pathTo)
      navigate(pathTo)
    }
  }, [rp.loading])
  return null
}

export const TechDocsRedirectExtension = techdocsRedirectPlugin.provide(
  createTechDocsAddonExtension({
    name: 'TechDocsRedirectExtension',
    location: TechDocsAddonLocations.Header,
    component: TechDocsRedirector
  })
)
