import React from 'react'

import Alert from '@material-ui/lab/Alert'
import { stringifyEntityRef } from '@backstage/catalog-model'
import { Progress } from '@backstage/core-components'

import { useAsync } from 'react-use'
import { useApi } from '@backstage/core-plugin-api'
import { useEntity, getEntitySourceLocation } from '@backstage/plugin-catalog-react'
import { scmIntegrationsApiRef } from '@backstage/integration-react'
import {
  gitlabAppData,
  gitlabAppSlug
} from '@internal/plugin-gitlab/src/components/gitlabAppData'

import { PipelineObject } from '@internal/plugin-gitlab/src/components/types'
import { GitlabCIApiRef } from '@internal/plugin-gitlab/src/api'
import { GitlabOverviewCard } from './GitlabOverviewCard'

function findLatestMainPipeline(pipelines: PipelineObject[]) {
  const mainPipelines = pipelines.filter(pl => pl.ref === 'main' || pl.ref === 'master')
  return mainPipelines.length > 0 ? mainPipelines[0] : undefined
}

export function EntityGitlabOverviewCard() {
  const { entity } = useEntity()
  const entityRef = stringifyEntityRef(entity)
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef)
  const repoHref = getEntitySourceLocation(entity, scmIntegrationsApi)?.locationTargetUrl

  const { project_id } = gitlabAppData()
  const { project_slug } = gitlabAppSlug()

  const GitlabCIAPI = useApi(GitlabCIApiRef)

  const { value, loading, error } = useAsync(async () => {
    const projectDetails: any = await GitlabCIAPI.getProjectDetails(project_slug)
    const projectId = project_id ? project_id : projectDetails?.id
    const mergeRequestsGitlabObj = await GitlabCIAPI.getMergeRequestsSummary(projectId)
    const pipelinesGitlabObj = await GitlabCIAPI.getPipelineSummary(projectId)
    const languagesGitlabObj = await GitlabCIAPI.getLanguagesSummary(projectId)
    return {
      mergeRequests: (mergeRequestsGitlabObj?.getMergeRequestsData || []).slice(0, 5),
      mainPipeline: findLatestMainPipeline(pipelinesGitlabObj?.getPipelinesData || []),
      languages: languagesGitlabObj?.getLanguagesData || []
    }
  }, [entityRef])

  if (loading) {
    return <Progress />
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>
  }

  return (
    <GitlabOverviewCard
      repoHref={repoHref}
      mergeRequests={value?.mergeRequests || []}
      mainPipeline={value?.mainPipeline}
      languages={value?.languages}
    />
  )
}
