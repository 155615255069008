import {
  createApiFactory,
  createApiRef,
  configApiRef,
  AppTheme
} from '@backstage/core-plugin-api'
import { DarkThemeProviderW, LightThemeProviderW } from './theme'

export type ThemeConfigAPI = {
  getThemeFamily(): string
  getThemes(): (Partial<AppTheme> & Omit<AppTheme, 'theme'>)[]
}

export const themeConfigApiRef = createApiRef<ThemeConfigAPI>({
  id: 'theme-config'
})

export const ThemeHolder = (themeFamily: string) => {
  return {
    getThemeFamily: function () {
      // later on we can find ways to make this dynamic and provide different theme families
      return themeFamily
    },
    getThemes: function (): (Partial<AppTheme> & Omit<AppTheme, 'theme'>)[] {
      return [
        {
          id: 'light',
          title: 'light theme',
          variant: 'light',
          Provider: LightThemeProviderW(this.getThemeFamily())
        },
        {
          id: 'dark',
          title: 'dark theme',
          variant: 'dark',
          Provider: DarkThemeProviderW(this.getThemeFamily())
        }
      ] //
    }
  }
}

export const themeConfigApi = createApiFactory({
  api: themeConfigApiRef,
  deps: { configApi: configApiRef },
  factory: ({ configApi }) => {
    let themeFamily: string
    try {
      themeFamily = configApi.getOptionalString('app.deployment') ?? 'development'
    } catch {
      themeFamily = 'development'
    }
    return ThemeHolder(themeFamily)
  }
})
