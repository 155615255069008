import React, { Children, PropsWithChildren } from 'react'
import { Grid, Divider, Typography } from '@material-ui/core'
import { useAsyncEntity } from '@backstage/plugin-catalog-react'
import { EntityAboutCard, EntityLinksCard } from '@backstage/plugin-catalog'
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph'
import { EntityTechInsightsOverviewFailedChecksCard } from '@internal/plugin-tech-insights-overview'
import { DatadogServiceCatalogLinksCard } from '@internal/plugin-datadog-service-catalog'
import { EntityWarningContent } from './EntityWarningContent'
import EntityTechnicalDetailsCard from './EntityTechnicalDetailsCard'

function formatBerlinTime(date: Date) {
  return date.toLocaleString('de-DE', { timeZone: 'Europe/Berlin' }) + ' (Berlin)'
}

function EntityFooter() {
  const { entity, loading } = useAsyncEntity()

  const lastProcessedAtIsoString =
    entity?.metadata.annotations?.['backstage.io/last-processed-at']
  const lastProcessedAt = lastProcessedAtIsoString && new Date(lastProcessedAtIsoString)
  const lastProcessedAtBerlinString = lastProcessedAt && formatBerlinTime(lastProcessedAt)

  return (
    <footer style={{ marginTop: '3rem' }}>
      <Divider />
      <Typography
        variant="body2"
        component="div"
        color="textSecondary"
        style={{ paddingTop: '1rem' }}
      >
        Entity last updated at:{' '}
        {loading ? '...' : lastProcessedAtBerlinString ?? '(never)'}
      </Typography>
    </footer>
  )
}

export function EntityOverviewContent(props: PropsWithChildren<{}>) {
  return (
    <>
      <EntityWarningContent />
      <Grid container>
        <Grid container item direction="column" lg={8} xs={12}>
          <Grid item>
            <EntityCatalogGraphCard variant="gridItem" height={500} />
          </Grid>
          <Grid item container direction="column">
            {Children.map(props.children, (child, idx) => (
              <Grid key={idx} item>
                {child}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container item direction="column" lg={4}>
          <Grid item>
            <EntityAboutCard variant="gridItem" />
          </Grid>
          <Grid item>
            <EntityTechnicalDetailsCard />
          </Grid>
          <Grid item>
            <EntityTechInsightsOverviewFailedChecksCard />
          </Grid>
          <Grid item>
            <EntityLinksCard />
          </Grid>
          <Grid item>
            <DatadogServiceCatalogLinksCard />
          </Grid>
        </Grid>
      </Grid>
      <EntityFooter />
    </>
  )
}
