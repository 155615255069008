import React from 'react'
import {
  DefaultEntityFilters,
  EntityAutocompletePicker
} from '@backstage/plugin-catalog-react'
import { EntityQueueTypeFilter } from './EntityResourcesFilter'

type CombinedFilters = DefaultEntityFilters & {
  queueType: EntityQueueTypeFilter
}

export type EntityQueueTypePickerProps = {
  showCounts?: boolean
}

export function EntityQueueTypePicker(props: EntityQueueTypePickerProps) {
  return (
    <EntityAutocompletePicker<CombinedFilters>
      label="Queue Type"
      name="queueType"
      path="metadata.annotations.sennder.com/queue-type"
      Filter={EntityQueueTypeFilter}
      showCounts={props.showCounts}
    />
  )
}
