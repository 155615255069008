import { createTheme, lightTheme, darkTheme } from '@backstage/theme'
import { CssBaseline, ThemeProvider } from '@material-ui/core'

import React, { PropsWithChildren } from 'react'

export const developmentThemeLight = createTheme({
  palette: {
    ...lightTheme.palette,
    background: {
      default: '#fffefe',
      paper: '#fffefe'
    }
  },
  defaultPageTheme: 'home'
})

export const productionThemeLight = createTheme({
  palette: {
    ...lightTheme.palette,
    background: {
      default: '#feffff',
      paper: '#feffff'
    }
  },
  defaultPageTheme: 'home'
})

export const developmentThemeDark = createTheme({
  palette: {
    ...darkTheme.palette,
    background: {
      default: '#151500',
      paper: '#151500'
    }
  },
  defaultPageTheme: 'home'
})

export const productionThemeDark = createTheme({
  palette: {
    ...darkTheme.palette,
    background: {
      default: '#001515',
      paper: '#001515'
    }
  },
  defaultPageTheme: 'home'
})

export const LightThemeProviderW = function (family: string) {
  return ({ children }: PropsWithChildren<{}>) => {
    const ltTheme =
      family === 'development' ? productionThemeLight : developmentThemeLight
    return (
      <ThemeProvider theme={ltTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    )
  }
}

export const DarkThemeProviderW = function (family: string) {
  return ({ children }: PropsWithChildren<{}>) => {
    const dkTheme = family === 'development' ? productionThemeDark : developmentThemeDark

    return (
      <ThemeProvider theme={dkTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    )
  }
}
