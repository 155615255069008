interface IBookmarkEntry {
  title: string
  description: string
  href: string
}

const bookmarks: IBookmarkEntry[] = [
  {
    title: 'Kafka Infrastructura',
    href: 'https://gitlab.com/sennder/engineering-effectiveness/platform/kafka/kafka-infrastructura',
    description:
      'All Kafka message schema definitions, i.e. contracts for communication between all services.'
  },
  {
    title: 'Global decisions log',
    href: 'https://gitlab.com/sennder/cross-cutting/tech-global-decision-log',
    description:
      'ADR’s (Architecture Decision Records) and RFC’s (Requests For Comments) which affect the whole sennder development landscape; know the future!'
  },
  {
    title: 'Replatformization',
    description: 'Resource scrapbook related to the replatformization process',
    href: 'https://docs.google.com/presentation/d/1HB-OSUvCTGRJmaeWGfDNtSueTHJuSCUj7HLsnpyBbNk/edit#slide=id.g121908d12a8_0_3597'
  },
  {
    title: 'Standardizations',
    description: 'sennder opportunities for universal standardization',
    href: 'https://sennder.atlassian.net/wiki/spaces/EE/pages/2527985673'
  }
]

export default bookmarks
