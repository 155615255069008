/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard
} from '@backstage/plugin-api-docs'
import {
  EntityDependencyOfComponentsCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  isKind
} from '@backstage/plugin-catalog'
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard
} from '@backstage/plugin-org'
import { OwnershipCard as EntityOwnershipCard } from '../OwnershipCard'
import { Direction, EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph'
import { EntityGitlabOverviewCard } from './EntityGitlabOverviewCard'
import { EntityTechInsightsOverviewPage } from '@internal/plugin-tech-insights-overview'
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API
} from '@backstage/catalog-model'

// GitlabCI Integration
import {
  isGitlabAvailable,
  EntityGitlabMergeRequestsTable,
  EntityGitlabPipelinesTable,
  EntityGitlabIssuesTable
} from '@internal/plugin-gitlab'
// local fork of @loblaw/backstage-plugin-gitlab

// OpsGenie plugin
// local fork of @k-phoen/backstage-plugin-opsgenie (from github.com/matchaxnb)
import {
  EntityOpsgenieAlertsCard,
  isOpsgenieAvailable
} from '@k-phoen/backstage-plugin-opsgenie'

// Datadog plugin
import {
  EntityDatadogContent,
  EntityDatadogGraphCard,
  isDatadogGraphAvailable
} from '@roadiehq/backstage-plugin-datadog'

// Tech Insights Overview plugin
import { EntityTechInsightsOverviewContent } from '@internal/plugin-tech-insights-overview'

// Dora plugin
import { EntityDoraContent } from '@internal/plugin-dora'

import {
  EntitySentryCard,
  EntitySentryContent,
  isSentryAvailable
} from '@backstage-community/plugin-sentry'

// SonarCloud Plugin
// TODO: Enable when SonarCloud will be properly configured
// import { EntitySonarQubeCard, isSonarQubeAvailable } from '@backstage-community/plugin-sonarqube'

// K8s plugin
// TODO: Enable when kubernetes will be properly configured
// import { EntityKubernetesContent } from '@backstage/plugin-kubernetes'

// external docs plugin
import {
  isExternalDocsAvailable,
  FrontendExternalDocsPage
} from '@internal/plugin-frontend-external-docs'

import { isMatching } from '../../utils'

import { EntityLayoutWrapper } from './EntityLayoutWrapper'
import { EntityWarningContent } from './EntityWarningContent'
import { EntityOverviewContent } from './EntityOverviewContent'
import EntityAdditionalInfoCard from './EntityAdditionalInfoCard'

const overviewContent = (
  <EntityOverviewContent>
    <EntityGitlabOverviewCard />
    {/* opsgenie plugin*/}
    <EntitySwitch>
      <EntitySwitch.Case if={isOpsgenieAvailable}>
        <EntityOpsgenieAlertsCard title="OpsGenie Alerts" />
      </EntitySwitch.Case>
    </EntitySwitch>
    {/* datadog plugin*/}
    <EntitySwitch>
      <EntitySwitch.Case if={isDatadogGraphAvailable}>
        <EntityDatadogGraphCard />
      </EntitySwitch.Case>
    </EntitySwitch>
    {/* TODO: Enable when SonarCloud will be properly configured*/}
    {/* sonarcloud plugin*/}
    {/*<EntitySwitch>*/}
    {/*  <EntitySwitch.Case if={isSonarQubeAvailable}>*/}
    {/*    <EntitySonarQubeCard variant="gridItem" />*/}
    {/*  </EntitySwitch.Case>*/}
    {/*</EntitySwitch>*/}
    {/* sentry plugin */}
    <EntitySwitch>
      <EntitySwitch.Case if={isSentryAvailable}>
        <EntitySentryCard />
      </EntitySwitch.Case>
    </EntitySwitch>
  </EntityOverviewContent>
)

const checksRoute = (
  <EntityLayout.Route path="/checks" title="Checks">
    <EntityTechInsightsOverviewPage />
  </EntityLayout.Route>
)

const serviceEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    {checksRoute}

    <EntityLayout.Route if={isGitlabAvailable} path="/issues" title="Issues">
      <EntityGitlabIssuesTable />
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isGitlabAvailable}
      path="/merge-requests"
      title="Merge Requests"
    >
      <EntityGitlabMergeRequestsTable />
    </EntityLayout.Route>

    <EntityLayout.Route if={isGitlabAvailable} path="/pipelines" title="CI/CD">
      <EntityGitlabPipelinesTable />
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isExternalDocsAvailable}
      path="/external-docs"
      title="External docs"
    >
      <FrontendExternalDocsPage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <EntityDependsOnComponentsCard />
        </Grid>
        <Grid item>
          <EntityDependencyOfComponentsCard />
        </Grid>
        <Grid item>
          <EntityDependsOnResourcesCard />
        </Grid>
        <Grid item>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item>
          <EntityConsumedApisCard />
        </Grid>
        <Grid item>
          <EntityHasSubcomponentsCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/datadog" title="Datadog">
      <EntityDatadogContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/sentry" title="Sentry">
      <EntitySentryContent />
    </EntityLayout.Route>

    {/* TODO: Enable when kubernetes will be properly configured*/}
    {/*<EntityLayout.Route path="/kubernetes" title="Kubernetes">*/}
    {/*  <EntityKubernetesContent />*/}
    {/*</EntityLayout.Route>*/}
  </EntityLayoutWrapper>
)

const backstageSystemEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Catalog files with incorrect contents">
      <Grid container alignItems="stretch">
        <Grid item xs={12}>
          <Typography>
            These are all the entities with processing errors. Fixed entities are removed
            from this list every day.
          </Typography>
          <EntityLinksCard cols={2} variant="fullHeight" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
)

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    {checksRoute}
  </EntityLayoutWrapper>
)

const apiPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <EntityOverviewContent>
        <EntityProvidingComponentsCard />
        <EntityConsumingComponentsCard />
      </EntityOverviewContent>
    </EntityLayout.Route>

    {checksRoute}

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
)

const userPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <EntityWarningContent />
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" groupBy="kind" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
)

const groupPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <EntityWarningContent />
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" groupBy="kind" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityAdditionalInfoCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityLinksCard />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/tech-insights-overview" title="Checks">
      <EntityTechInsightsOverviewContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/dora" title="DORA4">
      <EntityDoraContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
)

const systemPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <EntityOverviewContent>
        <EntityHasApisCard variant="gridItem" />
        <EntityHasComponentsCard variant="gridItem" />
        <EntityHasResourcesCard variant="gridItem" />
      </EntityOverviewContent>
    </EntityLayout.Route>

    {checksRoute}

    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
)

const domainPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <EntityOverviewContent>
        <EntityHasSystemsCard variant="gridItem" />
      </EntityOverviewContent>
    </EntityLayout.Route>

    {checksRoute}
  </EntityLayoutWrapper>
)

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isMatching('Service')} children={serviceEntityPage} />
    <EntitySwitch.Case
      if={isMatching('BackstageSystem')}
      children={backstageSystemEntityPage}
    />
    <EntitySwitch.Case children={serviceEntityPage} />
  </EntitySwitch>
)

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
)
