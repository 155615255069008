import React from 'react'

import { makeStyles, Typography, Link } from '@material-ui/core'
import { Header, Page, Content } from '@backstage/core-components'

const useStyles = makeStyles({
  embed: {
    width: '100%',
    minWidth: '100%',
    height: '1600px',
    marginBottom: '2rem'
  }
})

export function PlausibleAnalyticsPage() {
  const classes = useStyles()
  return (
    <Page themeId="home">
      <Header title={'Portal Statistics'} />
      <Content>
        <div> </div>
        <iframe
          plausible-embed="true"
          src="https://plausible.io/share/developers.sennder.tech?auth=HmjsJlKNcqymcSh-sAJjj&embed=true&theme=light"
          scrolling="no"
          frameBorder="0"
          loading="lazy"
          className={classes.embed}
        />
        <script async src="https://plausible.io/js/embed.host.js"></script>
        <Typography variant="caption" paragraph>
          Stats powered by{' '}
          <Link href="https://plausible.io" target="_blank">
            Plausible
          </Link>
          .
          <br />
          Account owner is viktor.nakoriakov@sennder.com
        </Typography>
      </Content>
    </Page>
  )
}
