import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Chip, Tooltip } from '@material-ui/core'
import { colors } from '@internal/plugin-gitlab/src/components/widgets/LanguagesCard/colors'

const useStyles = makeStyles(theme => ({
  infoCard: {
    marginBottom: theme.spacing(3),
    '& + .MuiAlert-root': {
      marginTop: theme.spacing(3)
    }
  },
  barContainer: {
    height: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: '4px',
    backgroundColor: 'transparent',
    overflow: 'hidden'
  },
  bar: {
    height: '100%',
    position: 'relative'
  },
  languageDot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    display: 'inline-block'
  },
  label: {
    color: 'inherit',
    fontSize: '12px'
  }
}))

export type Languages = {
  [key: string]: number
}

export function GitlabLanguageInfo(props: { languages: Languages }) {
  const classes = useStyles()
  let barWidth = 0
  let languageTitle = new String()
  return (
    <div>
      <div className={classes.barContainer}>
        {Object.entries(props.languages).map((language, index: number) => {
          barWidth = barWidth + language[1]
          languageTitle = language[0] + ' ' + language[1] + '%'
          return (
            <Tooltip title={languageTitle} placement="bottom-end" key={language[0]}>
              <div
                className={classes.bar}
                key={language[0]}
                style={{
                  marginTop: index === 0 ? '0' : `-16px`,
                  zIndex: Object.keys(props.languages).length - index,
                  backgroundColor: colors[language[0]]?.color || '#333',
                  width: `${barWidth}%`
                }}
              />
            </Tooltip>
          )
        })}
      </div>
      <div>
        {Object.entries(props.languages).map(language => (
          <Chip
            classes={{ label: classes.label }}
            label={
              <>
                <span
                  className={classes.languageDot}
                  style={{
                    backgroundColor: colors[language[0]]?.color || '#333'
                  }}
                />
                <b>{language[0]}</b> - {language[1]}%
              </>
            }
            variant="outlined"
            key={language[0]}
          />
        ))}
      </div>
    </div>
  )
}
