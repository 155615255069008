import React from 'react'
import {
  DefaultEntityFilters,
  EntityAutocompletePicker
} from '@backstage/plugin-catalog-react'
import { EntityServiceTypeFilter } from './EntityComponentsFilter'

type CombinedFilters = DefaultEntityFilters & {
  serviceType: EntityServiceTypeFilter
}

export type EntityServiceTypePickerProps = {
  showCounts?: boolean
}

export function EntityServiceTypePicker(props: EntityServiceTypePickerProps) {
  return (
    <EntityAutocompletePicker<CombinedFilters>
      label="Service Type"
      name="serviceType"
      path="metadata.annotations.sennder.com/service-type"
      Filter={EntityServiceTypeFilter}
      showCounts={props.showCounts}
    />
  )
}
