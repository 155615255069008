/**
 * This class is a customized version of the `EntityKindFilter` from the Backstage repository.
 *
 * Original Path: `@backstage/plugin-catalog-react/src/filters/EntityKindFilter.ts`
 *
 * Description:
 * - This file has been modified to support a "reset" functionality that displays all entities
 *   when the filter value is set to "entity."
 *
 * Modifications:
 * - Updated `getCatalogFilters()` to check if the filter value is "entity".
 *   - **Original:** `return { kind: this.value };`
 *   - **Modified:** `return { kind: this.value === 'entity' ? [] : [this.value] };`
 *   - This modification allows "entity" to act as a wildcard that matches all entity kinds,
 *     resetting the filter to show all items in the list.
 *
 * Purpose:
 * - Enhances the filter flexibility, allowing users to see all entities when "entity" is selected.
 *
 * Maintainer Notes:
 * - Future updates to Backstage’s `EntityKindFilter` might require reviewing and reapplying this modification.
 */

import { EntityFilter } from '@backstage/plugin-catalog-react'

export class CustomEntityKindFilter implements EntityFilter {
  constructor(readonly value: string) {}

  getCatalogFilters(): Record<string, string | string[]> {
    return { kind: this.value === 'entity' ? [] : [this.value] }
  }

  toQueryValue(): string {
    return this.value
  }
}
