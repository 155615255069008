import { scaffolderPlugin } from '@backstage/plugin-scaffolder'
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react'
import {
  SennderGitlabGroupPicker,
  validateSennderGitlabGroup
} from './SennderGitlabGroupPicker'

import { ComponentTypePicker, validateComponentType } from './ComponentTypePicker'

export const SennderGitlabGroupFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'SennderGitlabGroupPicker',
    component: SennderGitlabGroupPicker,
    validation: validateSennderGitlabGroup
  })
)

export const ComponentTypeFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ComponentTypePicker',
    component: ComponentTypePicker,
    validation: validateComponentType
  })
)
