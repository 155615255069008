import React from 'react'
import { Box, Grid, Typography, Link, makeStyles } from '@material-ui/core'
import {
  StatusPending,
  StatusRunning,
  StatusOK,
  StatusAborted,
  StatusError,
  InfoCard
} from '@backstage/core-components'
import {
  MergeRequest,
  PipelineObject
} from '@internal/plugin-gitlab/src/components/types'
import { getElapsedTime } from '@internal/plugin-gitlab/src/components/utils'
import { getStatusIconType } from '@internal/plugin-gitlab/src/components/widgets/MergeRequestsTable/Icons'
import { GitlabLanguageInfo } from './GitlabLanguageInfo'

export const GitlabCIStateIndicator = ({ state }: { state: string | undefined }) => {
  switch (state) {
    case 'pending':
      return <StatusPending />
    case 'in_progress':
      return <StatusRunning />
    case 'success':
      return <StatusOK />
    case 'ERROR':
    case 'failed':
      return <StatusError />
    default:
      return <StatusAborted />
  }
}

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  mainPipeline: {
    marginBottom: '1rem'
  }
}))

interface Languages {
  [key: string]: number
}

interface GitlabOverviewCardProps {
  repoHref?: string
  mergeRequests: MergeRequest[]
  mainPipeline?: PipelineObject
  languages: Languages
}

export function GitlabOverviewCard(props: GitlabOverviewCardProps) {
  const classes = useStyles()

  const mergeRequests = props.mergeRequests.map(mr => {
    return (
      <div key={mr.id}>
        <Typography variant="body2">
          {getStatusIconType(mr)}
          {' '}
          <Link href={mr.web_url} target="_blank">
            {mr.title}
          </Link>
        </Typography>
        <Typography variant="caption" paragraph>
          by {mr.author.username} {getElapsedTime(mr.created_at)}
        </Typography>
      </div>
    )
  })

  const mainPipeline = (props.mainPipeline && (
    <Box display="flex" alignItems="center">
      <GitlabCIStateIndicator state={props.mainPipeline.status} />
      <Typography variant="caption">
        <Link href={props.mainPipeline.web_url} target="_blank">
          {props.mainPipeline.status}
        </Link>{' '}
        {getElapsedTime(props.mainPipeline.created_at)}
      </Typography>
    </Box>
  )) || <Typography variant="caption">N/A</Typography>

  const deepLink = props.repoHref
    ? {
        title: 'Open GitLab repo',
        link: props.repoHref
      }
    : undefined

  return (
    <InfoCard title="GitLab Overview" deepLink={deepLink}>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Typography variant="h2" className={classes.label}>
            Merge Requests
          </Typography>
          {mergeRequests}
        </Grid>

        <Grid item container xs={12} lg={6} direction="column">
          <Grid item className={classes.mainPipeline}>
            <Typography variant="h2" className={classes.label}>
              Main Pipeline
            </Typography>
            {mainPipeline}
          </Grid>

          <Grid item>
            <Typography variant="h2" className={classes.label}>
              Languages
            </Typography>
            <GitlabLanguageInfo languages={props.languages} />
          </Grid>
        </Grid>
      </Grid>
    </InfoCard>
  )
}
