import { EntityFilter } from '@backstage/plugin-catalog-react'
import { Entity } from '@backstage/catalog-model'

export class EntityBaFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  filterEntity(entity: Entity): boolean {
    const baRef = entity.metadata.annotations?.['sennder.com/ba'] as string | undefined
    return baRef ? this.values.includes(baRef) : false
  }

  toQueryValue(): string[] {
    return this.values
  }
}
