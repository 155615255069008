import { Entity } from '@backstage/catalog-model'
import { useAsync } from 'react-use'
import { useApi, createApiRef, identityApiRef } from '@backstage/core-plugin-api'
import { CatalogApi } from '@backstage/catalog-client'
import { getEntityRelations } from '@backstage/plugin-catalog-react'

const catalogApiRef = createApiRef({
  id: 'plugin.catalog.service'
})

async function fetchBaOfPod(
  catalogApi: CatalogApi,
  podEntity: Entity
): Promise<Entity | undefined> {
  const baRef = getEntityRelations(podEntity, 'childOf')?.[0]
  if (!baRef) {
    return undefined
  }

  return catalogApi.getEntityByRef(baRef)
}

type UseMyBaAndPodResult = {
  value?: {
    error?: string
    baEntity?: Entity
    podEntity?: Entity
  }
  loading: boolean
  error?: any
}

export function useMyBaAndPod(): UseMyBaAndPodResult {
  const identityApi = useApi(identityApiRef)
  const catalogApi = useApi(catalogApiRef) as CatalogApi

  return useAsync(async () => {
    const identity = await identityApi.getBackstageIdentity()
    if (!identity) {
      return {
        error:
          `Cannot fetch your Backstage identity. ` +
          `This is a bug. ` +
          `Please, report to #topic-developer-portal.`
      }
    }

    const userRef = identity.userEntityRef

    let memberOfRef
    if (userRef === 'user:default/guest') {
      // Development mode on localhost. It has no auth by default.
      // Just force it to be DevX team
      memberOfRef = 'group:engineering-and-data-platform/developer-experience'
    } else {
      // Regular case
      const user = await catalogApi.getEntityByRef(userRef)
      if (!user) {
        return {
          error:
            `Cannot find a User entity associated with your account ` +
            `(${userRef}). ` +
            `Make sure you are properly onboarded yourself ` +
            `to the Developer Portal as an individual.`
        }
      }

      memberOfRef = getEntityRelations(user, 'memberOf')?.[0]
    }

    if (!memberOfRef) {
      return {
        error:
          `Cannot find your BA and pod ` +
          `(User: ${userRef}). ` +
          `Make sure you are listed in the respective pod or BA ` +
          `with “members” specification.`
      }
    }

    const memberOf = await catalogApi.getEntityByRef(memberOfRef)

    let baEntity
    let podEntity

    if (memberOf?.spec?.type === 'pod') {
      podEntity = memberOf
      baEntity = await fetchBaOfPod(catalogApi, podEntity)
    } else if (memberOf?.spec?.type === 'business-area') {
      baEntity = memberOf
    }

    return {
      baEntity,
      podEntity
    }
  }, [])
}
