import {
  createPlugin,
  createApiFactory,
  createRoutableExtension,
  discoveryApiRef
} from '@backstage/core-plugin-api'

import { rootRouteRef } from './routes'

import { PipelinesApiRef, PipelinesApiClient } from './api/pipelinesApiRef'
import { TestReportApiRef, TestReportApiClient } from './api/testReportApiRef'

export const qaTestResultsPlugin = createPlugin({
  id: 'qa-test-results',
  routes: {
    root: rootRouteRef
  },
  apis: [
    createApiFactory({
      api: PipelinesApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) => new PipelinesApiClient({ discoveryApi })
    }),
    createApiFactory({
      api: TestReportApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) => new TestReportApiClient({ discoveryApi })
    })
  ]
})

export const QaTestResultsPage = qaTestResultsPlugin.provide(
  createRoutableExtension({
    name: 'QaTestResultsPage',
    component: () => import('./components/QaPageComponent').then(m => m.QaPageComponent),
    mountPoint: rootRouteRef
  })
)
