import { ComponentEntity, Entity } from '@backstage/catalog-model'
import { ComponentTemplateConfig } from './config/templateMappings'

export const strCmp = (a: string | undefined, b: string | undefined) => {
  return Boolean(a && a?.toLocaleLowerCase('en-US') === b?.toLocaleLowerCase('en-US'))
}

export const strCmpAll = (value: string | undefined, cmpValues: string | string[]) => {
  return typeof cmpValues === 'string'
    ? strCmp(value, cmpValues)
    : cmpValues.some(cmpVal => strCmp(value, cmpVal))
}

export function isMatching(type: string) {
  return (entity: Entity) => {
    // bail if we do not know this kind of component in templateMappings
    if (!strCmp(entity.kind, 'component') || !ComponentTemplateConfig.has(type)) {
      return false
    }
    const cEntity = entity as ComponentEntity
    const toMatch = ComponentTemplateConfig.getStringArray(type)
    const cType = cEntity.spec.type

    const res = strCmpAll(cType, toMatch)
    return res
  }
}

export function sortBy(field: string, array: any[]) {
  return array.sort((a, b) => (a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0))
}

export function sortByTitle(array: any[]) {
  return sortBy('title', array)
}
