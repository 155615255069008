import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api'

export const rootRouteRef = createRouteRef({
  id: 'contrib'
})

export const issuesRouteRef = createSubRouteRef({
  id: 'contrib:issues',
  parent: rootRouteRef,
  path: '/issues'
})

export const aboutRouteRef = createSubRouteRef({
  id: 'contrib:about',
  parent: rootRouteRef,
  path: '/about'
})
