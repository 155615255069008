import { createApiRef, DiscoveryApi } from '@backstage/core-plugin-api'
import { TestSuite } from '../components/TestReportsTableComponent/TestReportsTable'

export interface TestReport {
  total_time: number
  total_count: number
  success_count: number
  failed_count: number
  skipped_count: number
  error_count: number
  test_suites: TestSuite[]
}

export interface TestReportApi {
  getTestReport: (projectId: number, pipelineId: number) => Promise<TestReport>
}

export const TestReportApiRef = createApiRef<TestReportApi>({
  id: 'plugin.test-report.service'
})

export class TestReportApiClient implements TestReportApi {
  discoveryApi: DiscoveryApi

  constructor({ discoveryApi }: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = discoveryApi
  }

  private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    // As configured previously for the backend proxy
    const proxyUri = `${await this.discoveryApi.getBaseUrl('proxy')}/gitlabci`

    const resp = await fetch(`${proxyUri}${input}`, init)
    if (!resp.ok) throw new Error(String(resp))
    return await resp.json()
  }

  async getTestReport(projectId: number, pipelineId: number): Promise<TestReport> {
    return await this.fetch<TestReport>(
      `/projects/${projectId}/pipelines/${pipelineId}/test_report`
    )
  }
}
