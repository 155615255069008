import React, { useState } from 'react'
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react'
import { FieldValidation } from '@rjsf/utils'
import { FormControl, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const AutocompleteType: string[] = [
  'service',
  'website',
  'library',
  'data-schema',
  'qa-test-suite',
  'documentation',
  'script',
  'tool',
  'template',
  'container-image',
  'data',
  'microfrontend',
  'iac',
  'misc'
]

export function ComponentTypePicker({
  onChange,
  rawErrors,
  required,
  formData
}: FieldExtensionComponentProps<string>) {
  const [typeValue, setTypeValue] = useState('')
  const [inputValue, setInputValue] = useState('')

  const handleChange = (_event: any, componentType: string | null) => {
    setTypeValue(componentType ?? '')
    onChange(componentType ?? '')
  }

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        id="componentType"
        value={typeValue}
        inputValue={inputValue}
        onChange={handleChange}
        onInputChange={(_event, text) => setInputValue(text)}
        options={AutocompleteType}
        autoSelect
        freeSolo
        renderInput={params => (
          <TextField
            {...params}
            label="Component Type"
            margin="dense"
            helperText="A Component type listed at 'Well-known Component Types'"
            FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
            variant="standard"
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
              )
            }}
          />
        )}
      />
    </FormControl>
  )
}

export function validateComponentType(value: string, validation: FieldValidation) {
  if (!AutocompleteType.includes(value)) {
    validation.addError(`Should be a valid Component Type. E.g.: service`)
  }
}
