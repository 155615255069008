export interface RadarEntrySnapshot {
  date: Date
  ringId: string
  description?: string
  moved?: MovedState
}
export enum MovedState {
  Down = -1,
  NoChange = 0,
  Up = 1
}
export interface RadarRing {
  id: string
  name: string
  color: string
}
export interface RadarRings {
  ADOPT: RadarRing
  TRIAL: RadarRing
  ASSESS: RadarRing
  HOLD: RadarRing
}
export interface RadarQuadrant {
  id: string
  name: string
  entries: Array<RadarEntry>
}
export interface RadarQuadrants {
  LANGUAGES: RadarQuadrant
  TOOLS: RadarQuadrant
  INFRASTRUCTURE: RadarQuadrant
  TECHNIQUES: RadarQuadrant
}
export interface RadarEntry {
  key: string
  id: string
  quadrant: string
  title: string
  url: string
  timeline: Array<RadarEntrySnapshot>
  description?: string
}
export enum COLORS {
  GREEN = '#93c47d',
  BLUE = '#93d2c2',
  YELLOW = '#fbdb84',
  RED = '#efafa9'
}
