import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api'

export const rootRouteRef = createRouteRef({
  id: 'dora'
})

export const breakdownRouteRef = createSubRouteRef({
  id: 'dora:breakdown',
  parent: rootRouteRef,
  path: '/breakdown'
})
