import React from 'react'
import {
  DefaultEntityFilters,
  EntityAutocompletePicker
} from '@backstage/plugin-catalog-react'
import { EntityPassedChecksFilter } from './EntityChecksFilter'

type CompbinedFilters = DefaultEntityFilters & {
  passedChecks?: EntityPassedChecksFilter
}

export type EntityPassedChecksPickerProps = {
  showCounts?: boolean
}

export function EntityPassedChecksPicker(props: EntityPassedChecksPickerProps) {
  return (
    <EntityAutocompletePicker<CompbinedFilters>
      label="Passed Checks"
      name="passedChecks"
      path="metadata.techInsightsSnapshot.failedChecks"
      Filter={EntityPassedChecksFilter}
      showCounts={props.showCounts}
    />
  )
}
