import React from 'react'
import {
  DefaultEntityFilters,
  EntityAutocompletePicker
} from '@backstage/plugin-catalog-react'
import { EntityExecutionModeFilter } from './EntityComponentsFilter'

type CombinedFilters = DefaultEntityFilters & {
  executionMode: EntityExecutionModeFilter
}

export type EntityExecutionModePickerProps = {
  showCounts?: boolean
}

export function EntityExecutionModePicker(props: EntityExecutionModePickerProps) {
  return (
    <EntityAutocompletePicker<CombinedFilters>
      label="Execution Mode"
      name="executionMode"
      path="metadata.annotations.sennder.com/execution-mode"
      Filter={EntityExecutionModeFilter}
      showCounts={props.showCounts}
    />
  )
}
