import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api'

import { rootRouteRef } from './routes'
import { Entity } from '@backstage/catalog-model'

export const frontendExternalDocsPlugin = createPlugin({
  id: 'frontend-external-docs',
  routes: {
    root: rootRouteRef
  }
})

export const FrontendExternalDocsPage = frontendExternalDocsPlugin.provide(
  createRoutableExtension({
    name: 'FrontendExternalDocsPage',
    component: () =>
      import('./components/ExternalDocsDisplayer').then(m => m.ExternalDocsDisplayer),
    mountPoint: rootRouteRef
  })
)

export const isExternalDocsAvailable = (entity: Entity) => {
  // external docs available only if we have at least one "external-doc" link
  // ensure boolean type and so on
  return Boolean(
    (entity.metadata?.links?.filter(({ type }) => type == 'external-doc') || []).length >
      0
  )
}
