import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api'
import { rootRouteRef } from './routes'

export const contribPlugin = createPlugin({
  id: 'contrib',
  routes: {
    root: rootRouteRef
  }
})

export const ContribPage = contribPlugin.provide(
  createRoutableExtension({
    name: 'ContribPage',
    component: () => import('./Router').then(m => m.ContribRouter),
    mountPoint: rootRouteRef
  })
)
