import { createApiRef, DiscoveryApi } from '@backstage/core-plugin-api'

export interface Pipeline {
  id: number
  status: string
  web_url: string
  ref: string
  started_at: string
  user: {
    name: string
  }
}

export interface PipelinesApi {
  listPipelines: (projectId: number, perPage: number) => Promise<Array<Pipeline>>
  getPipeline: (projectId: number, pipelineId: number) => Promise<Pipeline>
}

export const PipelinesApiRef = createApiRef<PipelinesApi>({
  id: 'plugin.pipelines.service'
})

export class PipelinesApiClient implements PipelinesApi {
  discoveryApi: DiscoveryApi

  constructor({ discoveryApi }: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = discoveryApi
  }

  private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    // As configured previously for the backend proxy
    const proxyUri = `${await this.discoveryApi.getBaseUrl('proxy')}/gitlabci`

    const resp = await fetch(`${proxyUri}${input}`, init)
    if (!resp.ok) throw new Error(String(resp))
    return await resp.json()
  }

  async listPipelines(projectId: number, perPage: number = 20): Promise<Array<Pipeline>> {
    return await this.fetch<Array<Pipeline>>(
      `/projects/${projectId}/pipelines?per_page=${perPage}`
    )
  }

  async getPipeline(projectId: number, pipelineId: number): Promise<Pipeline> {
    return await this.fetch<Pipeline>(`/projects/${projectId}/pipelines/${pipelineId}`)
  }
}
