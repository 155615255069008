// plugin-api-docs, move out later
import {
  defaultDefinitionWidgets,
  ApiDefinitionWidget,
  AsyncApiDefinitionWidgetProps
} from '@backstage/plugin-api-docs'
import { ApiEntity } from '@backstage/catalog-model'
import React, { Suspense } from 'react'
import { Progress } from '@backstage/core-components'
const LazyAsyncApiDefinition = React.lazy(() =>
  import('./AsyncApiDefinition').then(m => ({
    default: m.AsyncApiDefinition
  }))
)

export const AsyncApiDefinitionWidget = (props: AsyncApiDefinitionWidgetProps) => {
  return (
    <Suspense fallback={<Progress />}>
      <LazyAsyncApiDefinition {...props} />
    </Suspense>
  )
}

export const ApiDocPluginFactory = () => {
  const definitionWidgets = defaultDefinitionWidgets()

  return {
    getApiDefinitionWidget: (apiEntity: ApiEntity) => {
      const widget = definitionWidgets.find(d => d.type === apiEntity.spec.type)
      if (apiEntity.spec.type === 'asyncapi') {
        // implement the override for GitLab-CI proxying
        return {
          type: 'asyncapi',
          title: 'AsyncAPI',
          component: definition => <AsyncApiDefinitionWidget definition={definition} />
        } as ApiDefinitionWidget
      }
      return widget
    }
  }
}
