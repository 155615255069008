import React from 'react'
import { useEntity } from '@backstage/plugin-catalog-react'
import { Box, Card, CardContent, CardHeader, Divider } from '@material-ui/core'
import SlackChannelField from './EntityFields/Slack/SlackChannelField'

const EntityAdditionalInfoCard = () => {
  const { entity } = useEntity()
  return (
    <Card>
      <CardHeader title="Additional Information" />
      <Divider />
      <CardContent>
        <Box display="flex" flexDirection="column">
          <SlackChannelField entity={entity} />
        </Box>
      </CardContent>
    </Card>
  )
}

export default EntityAdditionalInfoCard
