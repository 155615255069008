import { COLORS, MovedState, RadarEntry, RadarQuadrants, RadarRings } from './types'

/*
The Entry Timeline order defines the order in which the entries will be displayed in the radar,
not the date added to the timeline.
The timeline at the top will define in which ring the entry is.
 */

export const RINGS: RadarRings = {
  ADOPT: {
    id: 'adopt',
    name: 'ADOPT',
    color: COLORS.GREEN
  },
  TRIAL: {
    id: 'trial',
    name: 'TRIAL',
    color: COLORS.BLUE
  },
  ASSESS: {
    id: 'assess',
    name: 'ASSESS',
    color: COLORS.YELLOW
  },
  HOLD: {
    id: 'hold',
    name: 'HOLD',
    color: COLORS.RED
  }
}

const languagesEntries: Array<RadarEntry> = [
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.python.org/',
    key: 'python',
    id: 'python',
    title: 'Python',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Python is a programming language that lets you work quickly\n' +
      'and integrate systems more effectively.'
  },
  {
    timeline: [
      {
        moved: MovedState.Down,
        ringId: RINGS.HOLD.id,
        date: new Date('2024-08-13'),
        description: 'Should only be used in specific and approved cases'
      },
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://go.dev/',
    key: 'go',
    id: 'go',
    title: 'Go',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Build simple, secure, scalable systems with Go'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.HOLD.id,
        date: new Date('2024-08-13'),
        description: 'Should only be used in specific and approved cases'
      }
    ],
    url: 'https://www.java.com/en/',
    key: 'java',
    id: 'java',
    title: 'Java',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Java is a high-level, class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://nodejs.org/en/',
    key: 'node',
    id: 'node',
    title: 'Node.js',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Node.js® is an open-source, cross-platform JavaScript runtime environment.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.fastify.io/',
    key: 'fastify',
    id: 'fastify',
    title: 'Fastify',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Fastify is proudly powering a large ecosystem of organisations and products out there.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ASSESS.id,
        date: new Date('2024-08-13'),
        description: 'This is being assessed and needs approval to move to trial'
      }
    ],
    url: 'https://nestjs.com/',
    key: 'nestjs',
    id: 'nestjs',
    title: 'NestJS',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'A progressive Node.js framework for building efficient, reliable and scalable server-side applications.'
  },
  {
    timeline: [
      {
        moved: MovedState.Down,
        ringId: RINGS.HOLD.id,
        date: new Date('2024-08-13'),
        description: 'Only used by Mothership'
      },
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.djangoproject.com/',
    key: 'django',
    id: 'django',
    title: 'Django',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Django makes it easier to build better web apps more quickly and with less code.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://docs.celeryq.dev/en/stable/',
    key: 'celery',
    id: 'celery',
    title: 'Celery',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Celery is a simple, flexible, and reliable distributed system to process vast amounts of messages, while providing operations with the tools required to maintain such a system.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://python-poetry.org/',
    key: 'poetry',
    id: 'poetry',
    title: 'Poetry',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'PYTHON PACKAGING AND DEPENDENCY MANAGEMENT MADE EASY'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://alembic.sqlalchemy.org/en/latest/',
    key: 'alembic',
    id: 'alembic',
    title: 'Alembic',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Alembic is a lightweight database migration tool for usage with the SQLAlchemy Database Toolkit for Python.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://fastapi.tiangolo.com/',
    key: 'fastapi',
    id: 'fastapi',
    title: 'FastAPI',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'FastAPI framework, high performance, easy to learn, fast to code, ready for production'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.typescriptlang.org/',
    key: 'typescript',
    id: 'typescript',
    title: 'TypeScript',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'TypeScript is a strongly typed programming language that builds on JavaScript, giving you better tooling at any scale.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.HOLD.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.javascript.com/',
    key: 'javascript',
    id: 'javascript',
    title: 'JavaScript',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'JavaScript is a programming language that is one of the core technologies of the World Wide Web, alongside HTML and CSS'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://vuejs.org/',
    key: 'vuejs',
    id: 'vuejs',
    title: 'Vue.js 3.x',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'An approachable, performant and versatile framework for building web user interfaces.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.HOLD.id,
        date: new Date('2024-01-23'),
        description: ''
      }
    ],
    url: 'https://vuejs.org/',
    key: 'vuejs',
    id: 'vuejs',
    title: 'Vue.js 2.x',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'An approachable, performant, and versatile framework for building web user interfaces, Vue 3.x is mandatory for new applications, while some legacy ones still use Vue 2.x.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2024-01-23'),
        description: ''
      }
    ],
    url: 'https://pinia.vuejs.org/',
    key: 'pinia',
    id: 'pinia',
    title: 'Pinia',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'The intuitive store for Vue.js recommended by the Vue.js team'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.HOLD.id,
        date: new Date('2024-01-23'),
        description: ''
      }
    ],
    url: 'https://vuex.vuejs.org/',
    key: 'vuex',
    id: 'vuex',
    title: 'Vuex',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Vuex is a state management pattern + library for Vue.js applications. Currently is being replaced by Pinia.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://jestjs.io/',
    key: 'jest',
    id: 'jest',
    title: 'Jest',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Jest is a delightful JavaScript Testing Framework with a focus on simplicity.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://testing-library.com/',
    key: 'testinglibrary',
    id: 'testinglibrary',
    title: 'Testing Library',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Frontend simple and complete testing utilities that encourage good testing practices'
  },
  {
    timeline: [
      {
        moved: MovedState.Down,
        ringId: RINGS.HOLD.id,
        date: new Date('2024-08-13'),
        description: 'Superseded by Playwright'
      },
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.cypress.io/',
    key: 'cypress',
    id: 'cypress',
    title: 'Cypress',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'With Cypress, you can easily create tests for your modern web applications, debug them visually, and automatically run them in your continuous integration builds.'
  },
  {
    timeline: [
      {
        moved: MovedState.Down,
        ringId: RINGS.HOLD.id,
        date: new Date('2024-08-13'),
        description: 'Superseded by Playwright'
      },
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://webdriver.io/',
    key: 'webdriverio',
    id: 'webdriverio',
    title: 'Webdriver.io',
    quadrant: '', // Quadrant will be defined during compilation
    description: ''
  },
  {
    timeline: [
      {
        moved: MovedState.Down,
        ringId: RINGS.HOLD.id,
        date: new Date('2024-08-13'),
        description: 'Should only be used in specific and approved cases such as Mobile'
      },
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://kotlinlang.org/',
    key: 'kotlin',
    id: 'kotlin',
    title: 'Kotlin',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'A modern programming language\n' + 'that makes developers happier.'
  },
  {
    timeline: [
      {
        moved: MovedState.Down,
        ringId: RINGS.HOLD.id,
        date: new Date('2024-08-13'),
        description: 'Should only be used in specific and approved cases such as Mobile'
      },
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.swift.org/',
    key: 'swift',
    id: 'swift',
    title: 'Swift',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Swift is a powerful and intuitive programming language for all Apple platforms.'
  }
]

const toolsEntries: Array<RadarEntry> = [
  {
    timeline: [
      {
        moved: MovedState.Down,
        ringId: RINGS.HOLD.id,
        date: new Date('2024-08-13'),
        description: 'Deprecated in favor of Datadog'
      },
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://sentry.io/',
    key: 'sentry',
    id: 'sentry',
    title: 'Sentry',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Open-source error tracking with full stacktraces & asynchronous context.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://app.datadoghq.eu/',
    key: 'datadog',
    id: 'datadog',
    title: 'Datadog',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Datadog is an observability service for cloud-scale applications, providing monitoring of servers, databases, tools, and services, through a SaaS-based data analytics platform.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://miro.com/',
    key: 'miro',
    id: 'miro',
    title: 'Miro',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Miro is visual platform to connect, collaborate, and create — together.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://helm.sh/',
    key: 'helm',
    id: 'help',
    title: 'Helm',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'The package manager for Kubernetes'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://github.com/hound-search/hound',
    key: 'hound',
    id: 'hound',
    title: 'Hound',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Hound is a tool for quick search in gitlab repositories'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://launchdarkly.com/',
    key: 'launchdarkly',
    id: 'launchdarkly',
    title: 'LaunchDarkly',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Platform for managing feature flags'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.atlassian.com/software/opsgenie',
    key: 'opsgenie',
    id: 'opsgenie',
    title: 'Opsgenie',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'On-call and alert management to keep services always on'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.HOLD.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://cookiecutter.readthedocs.io/en/stable/',
    key: 'cookiecutter',
    id: 'cookiecutter',
    title: 'Cookiecutter',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Cookiecutter creates projects from cookiecutters (project templates), e.g. Python package projects from Python package temnplates.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.TRIAL.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.sonarsource.com/',
    key: 'sonar',
    id: 'sonar',
    title: 'Sonar',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'SonarSource is an open source software for continuous code quality and security.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.pylint.org/',
    key: 'pylint',
    id: 'pylint',
    title: 'Pylint',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Python linting tool'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://eslint.org/',
    key: 'eslint',
    id: 'eslint',
    title: 'ESLint',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'JavaScript and TypeScript linting tool'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://prettier.io/',
    key: 'prettier',
    id: 'prettier',
    title: 'Prettier',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Code formatter'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://lefthook.com/',
    key: 'lefthook',
    id: 'lefthook',
    title: 'Lefthook',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Fast and powerful Git hooks manager for Node.js, Ruby or any other type of projects.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.HOLD.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.npmjs.com/package/husky',
    key: 'husky',
    id: 'husky',
    title: 'Husky',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Modern native Git hooks made easy'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://poeditor.com/',
    key: 'poeditor',
    id: 'poeditor',
    title: 'POEditor',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'The localization management platform'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.fullstory.com/',
    key: 'fullstory',
    id: 'fullstory',
    title: 'Fullstory',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'FullStory tells you everything you need to know about your customers digital experience.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://sennder.atlassian.net/wiki/spaces/SET/pages/2593423574/How+to+use+outbox-worker',
    key: 'outboxworker',
    id: 'outboxworker',
    title: 'Inner tool: Outbox-worker',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Outbox-worker is a service that gives you transactional guarantee when you are writing(publishing) to Kafka.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.HOLD.id,
        date: new Date('2024-03-07'),
        description: ''
      }
    ],
    url: 'https://ksqldb.io/',
    key: 'ksqldb',
    id: 'ksqldb',
    title: 'ksqlDB',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'The database purpose-built for stream processing applications.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.postgresql.org/',
    key: 'postgresql',
    id: 'postgresql',
    title: 'PostgreSQL',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'PostgreSQL is a powerful, open source object-relational database system'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://aws.amazon.com/dynamodb/',
    key: 'dynamodb',
    id: 'dynamodb',
    title: 'Amazon DynamoDB',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Fast, flexible NoSQL database service for single-digit millisecond performance at any scale'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/sennder/cross-cutting/sennder-devtools',
    key: 'sennderdevtools',
    id: 'sennderdevtools',
    title: 'Inner tool: sennder Devtools',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'The sennder internal tool to scaffold new services, libraries, frontend applications, and microfrontend.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/sennder/cross-cutting/senn-node/ts-templates',
    key: 'senndertstemplates',
    id: 'senndertstemplates',
    title: 'Inner tool: sennder devtools templates',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Templates for sennder devtools'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/sennder/operations-finance/assignment/sennder-kafka-idempotent-consumer',
    key: 'sennderkafkaconsumer',
    id: 'sennderkafkaconsumer',
    title: 'Inner tool: sennder kafka consumer',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Kafka Consumer lib that provides a Kafka Consumer that makes sure order of messages is preserved on the consumer side. Via the OrderedIdempotentConsumer we do Idempotent inserts(upserts).'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.HOLD.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/sennder/cross-cutting/plankton',
    key: 'sennderplankton',
    id: 'sennderplankton',
    title: 'Inner tool: sennder UI Library Plankton',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Components and utilities shared by sennder web apps'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.HOLD.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/sennder/cross-cutting/coral',
    key: 'senndercoral',
    id: 'senndercoral',
    title: 'Inner tool: sennder UI Library Coral',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Components and utilities shared by sennder web apps'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/sennder/carriers-drivers/translations',
    key: 'senndertranslations',
    id: 'senndertranslations',
    title: 'Inner tool: translations service',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Service to store translations from POEditor'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/sennder/cross-cutting/senn-node/senn-node-google-tag',
    key: 'senndergoogetag',
    id: 'senndergoogletag',
    title: 'Inner tool: sennder google tag manager',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Simple wrapper to include Google Tag Manager in frontend applications'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/sennder/cross-cutting/senn-node/senn-node-auth0-frontend',
    key: 'sennderauth0frontend',
    id: 'sennderauth0frontend',
    title: 'Inner tool: sennder auth0 frontend',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Simple wrapper to include auth0 in frontend applications'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/sennder/cross-cutting/senn-node/senn-node-microfrontends-event-bus',
    key: 'senndermicrofrontendseventbus',
    id: 'senndermicrofrontendseventbus',
    title: 'Inner tool: sennder frontend event bus library',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'sennder frontend event bus library'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.HOLD.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/sennder/operations-finance/execution/notification-center-frontend',
    key: 'senndernotificationcenterfrontend',
    id: 'senndernotificationcenterfrontend',
    title: 'Inner tool: sennder notifications vue2 wrapper',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'sennder frontend vue2 wrapper for notifications'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/sennder/cross-cutting/senn-node/senn-node-push-notifications-frontend',
    key: 'senndernotificationsclient',
    id: 'senndernotificationsclient',
    title: 'Inner tool: sennder push notifications library',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'sennder frontend agnostic push notifications library'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/sennder/cross-cutting/senn-node/senn-node-push-notifications-components',
    key: 'senndernotificationscomponents',
    id: 'senndernotificationscomponents',
    title: 'Inner tool: sennder push notifications library components',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'sennder push notifications components for frontend client'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/sennder/cross-cutting/senn-node/senn-node-feature-flags-frontend',
    key: 'sennderfeatureflagsfrontend',
    id: 'sennderfeatureflagsfrontend',
    title: 'Inner tool: sennder feature flags frontend',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Simple wrapper to connect LaunchDarkly to frontend applications'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/sennder/cross-cutting/senn-node/senn-node-analytics-frontend',
    key: 'sennderanalyticsfrontend',
    id: 'sennderanalyticsfrontend',
    title: 'Inner tool: sennder analytics frontend library',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'sennder frontend library to connect analytics'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2024-01-23'),
        description: ''
      }
    ],
    url: 'https://www.npmjs.com/',
    key: 'npm',
    id: 'npm',
    title: 'npm',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'npm is a package manager for the JavaScript programming language maintained by npm, Inc. npm is the default package manager for the JavaScript runtime environment Node.js and is included as a recommended feature in the Node.js installer.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2024-01-23'),
        description: ''
      }
    ],
    url: 'https://developers.google.com/maps',
    key: 'google-maps',
    id: 'google-maps',
    title: 'Google Maps Platform',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Google Maps Platform is a set of APIs and SDKs for retrieving location-based data from Google and embedding Google Maps imagery into mobile apps and web pages.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2024-01-23'),
        description: ''
      }
    ],
    url: 'https://developer.here.com/',
    key: 'here-maps',
    id: 'here-maps',
    title: 'Here Maps Platform',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'HERE Maps SDK enables developers to build powerful map applications.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2024-08-13'),
        description: ''
      }
    ],
    url: 'https://permify.co/',
    key: 'permify',
    id: 'permify',
    title: 'Permify',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Implement fine-grained, scalable and extensible access controls within minutes to days instead of months. ' +
      'Inspired by Google’s consistent, global authorization system, Zanzibar. ' +
      'This tool is being used by the Auth V4 Authorization lambda.'
  }
]

const infrastructureEntries: Array<RadarEntry> = [
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://aws.amazon.com/',
    key: 'aws',
    id: 'aws',
    title: 'Amazon Web Services',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Build, Deploy, and Manage Websites, Apps or Processes On AWS Secure, Reliable Network.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://kafka.apache.org/',
    key: 'kafka',
    id: 'kafka',
    title: 'Apache Kafka',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Apache Kafka is an open-source distributed event streaming platform.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.confluent.io/',
    key: 'confluent',
    id: 'confluent',
    title: 'Confluent',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Confluent is building the foundational platform for data in motion so any organization can innovate and win in a digital-first world'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://backstage.io/',
    key: 'backstage',
    id: 'backstage',
    title: 'Backstage',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'An open platform for building developer portals'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://gitlab.com/',
    key: 'gitlab',
    id: 'gitlab',
    title: 'GitLab',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'GitLab brings teams together to shorten cycle times, reduce costs, strengthen security, and increase developer productivity.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.snowflake.com/en/',
    key: 'snowflake',
    id: 'snowflake',
    title: 'Snowflake',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Where Python developers can learn about the latest on data engineering and data science modern processing in Snowflake.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://kubernetes.io/',
    key: 'kubernetes',
    id: 'kubernetes',
    title: 'Kubernetes',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Kubernetes, also known as K8s, is an open-source system for automating deployment, scaling, and management of containerized applications.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://auth0.com/',
    key: 'auth0',
    id: 'auth0',
    title: 'Auth0',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Rapidly integrate authentication and authorization for web, mobile, and legacy applications so you can focus on your core business.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.terraform.io/',
    key: 'terraform',
    id: 'terraform',
    title: 'Terraform',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Terraform is an infrastructure as code tool that lets you build, change, and version infrastructure safely and efficiently.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://www.docker.com/',
    key: 'docker',
    id: 'docker',
    title: 'Docker',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Docker is a set of platform as a service products that use OS-level virtualization to deliver software in packages called containers.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2024-08-13'),
        description: ''
      }
    ],
    url: 'https://sennder.atlassian.net/wiki/spaces/WFLS/overview',
    key: 'workflow',
    id: 'workflow',
    title: 'Inner Tool: Workflow',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'The workflow platform provides a central orchestration engine for our microservices architecture. This should avoid the pain points we had with the monolith.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2024-08-13'),
        description: ''
      }
    ],
    url: 'https://sennder.atlassian.net/wiki/spaces/SEARCH/pages/2848686748/Search+Platform',
    key: 'sennsearch',
    id: 'sennsearch',
    title: 'Inner Tool: sennSearch',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'Powerful search API behind a unified GraphQL interface.'
  }
]

const techniquesEntries = [
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://c4model.com/',
    key: 'c4model',
    id: 'c4model',
    title: 'C4 model',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'The C4 model is a lean graphical notation technique for modelling the architecture of software systems.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: 'https://webpack.js.org/concepts/module-federation/',
    key: 'modulefederation',
    id: 'modulefederation',
    title: 'Webpack module federation',
    quadrant: '', // Quadrant will be defined during compilation
    description: 'sennder approach to build microfrontends'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ADOPT.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: '',
    key: 'iac',
    id: 'iac',
    title: 'Infrastructure as code',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Infrastructure as Code (IaC) is the managing and provisioning of infrastructure through code instead of through manual processes. '
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ASSESS.id,
        date: new Date('2023-01-20'),
        description: ''
      }
    ],
    url: '',
    key: 'contractbasedtesting',
    id: 'contractbasedtesting',
    title: 'Contract based testing',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'Contract testing is a methodology for ensuring that two separate systems (such as two microservices) are compatible and can communicate.'
  },
  {
    timeline: [
      {
        moved: MovedState.NoChange,
        ringId: RINGS.ASSESS.id,
        date: new Date('2024-08-23'),
        description: ''
      }
    ],
    url: 'https://www.eventstorming.com/',
    key: 'eventstorming',
    id: 'eventstorming',
    title: 'Event Storming',
    quadrant: '', // Quadrant will be defined during compilation
    description:
      'EventStorming is a flexible workshop format for collaborative exploration of complex business domains.'
  }
]
export const QUADRANTS: RadarQuadrants = {
  LANGUAGES: {
    id: 'languages',
    name: 'Languages and Frameworks',
    entries: languagesEntries
  },
  TOOLS: {
    id: 'tools',
    name: 'Internal and External Tools',
    entries: toolsEntries
  },
  INFRASTRUCTURE: {
    id: 'infrastructure',
    name: 'Platforms & Infrastructure',
    entries: infrastructureEntries
  },
  TECHNIQUES: {
    id: 'techniques',
    name: 'Techniques',
    entries: techniquesEntries
  }
}
