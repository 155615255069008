import React from 'react'
import {
  DefaultEntityFilters,
  EntityAutocompletePicker
} from '@backstage/plugin-catalog-react'
import { EntityDatabaseTypeFilter } from './EntityResourcesFilter'

type CombinedFilters = DefaultEntityFilters & {
  databaseType: EntityDatabaseTypeFilter
}

export type EntityDatabaseTypePickerProps = {
  showCounts?: boolean
}

export function EntityDatabaseTypePicker(props: EntityDatabaseTypePickerProps) {
  return (
    <EntityAutocompletePicker<CombinedFilters>
      label="Database Type"
      name="databaseType"
      path="metadata.annotations.sennder.com/database-type"
      Filter={EntityDatabaseTypeFilter}
      showCounts={props.showCounts}
    />
  )
}
