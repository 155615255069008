import React, { useState, useEffect } from 'react'
import { DefaultEntityFilters, useEntityList } from '@backstage/plugin-catalog-react'
import {
  EntityArchivalStateFilter,
  EntityArchivalStateValue
} from './EntityArchivalStateFilter'
import { Select } from '@backstage/core-components'

type CombinedFilters = DefaultEntityFilters & {
  archivalState?: EntityArchivalStateFilter
}

export type EntityArchivalStatePickerProps = {
  initialFilter?: EntityArchivalStateValue
}

export function EntityArchivalStatePicker(props: EntityArchivalStatePickerProps) {
  const { initialFilter } = props
  const {
    updateFilters,
    filters,
    queryParameters: { archivalState: archivalStateQueryParameter }
  } = useEntityList<CombinedFilters>()

  const [selection, setSelection] = useState(
    () => filters.archivalState?.value ?? initialFilter
  )

  useEffect(() => {
    updateFilters({
      archivalState: new EntityArchivalStateFilter(selection)
    } as Partial<CombinedFilters>)
  }, [selection])

  useEffect(() => {
    if (initialFilter) {
      updateFilters({
        archivalState: new EntityArchivalStateFilter(initialFilter)
      } as Partial<CombinedFilters>)
    }
  }, [initialFilter])

  // Set selected options on query parameter updates; this happens at initial page load and from
  // external updates to the page location
  useEffect(() => {
    if (archivalStateQueryParameter?.length) {
      setSelection(archivalStateQueryParameter as EntityArchivalStateValue)
    }
  }, [archivalStateQueryParameter])

  const items = [
    { value: 'all', label: 'all' },
    { value: 'archived', label: 'Archived' },
    { value: 'non-archived', label: 'Non-archived' }
  ]

  return (
    <Select
      label="Archival State"
      items={items}
      selected={selection ?? 'all'}
      onChange={value =>
        setSelection(value === 'all' ? undefined : (value as EntityArchivalStateValue))
      }
    />
  )
}
