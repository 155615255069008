import {
  createPlugin,
  createRoutableExtension,
  createComponentExtension
} from '@backstage/core-plugin-api'

import { rootRouteRef, breakdownRouteRef } from './routes'

export const doraPlugin = createPlugin({
  id: 'dora',
  routes: {
    root: rootRouteRef,
    breakdown: breakdownRouteRef
  }
})

export const DoraPage = doraPlugin.provide(
  createRoutableExtension({
    name: 'DoraPage',
    component: () => import('./components/DoraPage').then(m => m.DoraPage),
    mountPoint: rootRouteRef
  })
)

export const EntityDoraContent = doraPlugin.provide(
  createComponentExtension({
    name: 'EntityDoraContent',
    component: {
      lazy: () => import('./components/EntityContent').then(m => m.EntityContent)
    }
  })
)

export const MyGroupSummaryDoraContent = doraPlugin.provide(
  createComponentExtension({
    name: 'MyGroupSummaryDoraContent',
    component: {
      lazy: () =>
        import('./components/MyGroupSummaryContent').then(m => m.MyGroupSummaryContent)
    }
  })
)
