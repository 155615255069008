import React from 'react'
import { BackstageTheme } from '@backstage/theme'
import { makeStyles, Grid, Box } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { InfoCard, Progress } from '@backstage/core-components'

import { OwnershipCard } from '../OwnershipCard'
import { EntityProvider } from '@backstage/plugin-catalog-react'
import { EntityTechInsightsOverviewCard } from '@internal/plugin-tech-insights-overview'

import { MyGroupSummaryDoraContent } from '@internal/plugin-dora'
import { useMyBaAndPod } from '../../hooks/my-group'

const useStyles = makeStyles<BackstageTheme>(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))

export function AboveTheFold() {
  const classes = useStyles()

  const { value, loading, error } = useMyBaAndPod()

  if (loading) {
    return <Progress />
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>
  }

  if (!value) {
    // Impossible branch
    return (
      <Alert severity="error">
        Unexpected kukurookoo. Please, report to #topic-developer-portal.
      </Alert>
    )
  }

  if (value.error) {
    return <Alert severity="error">{value.error}</Alert>
  }

  const { baEntity, podEntity } = value

  const helpHrefBase =
    'https://developers.sennder.tech/docs/engineering-and-data-platform/component/dora4-docs'

  return (
    <Box className={classes.root}>
      <Grid container spacing={2} alignContent="stretch">
        <Grid item xs={12} md={8} lg={6}>
          <InfoCard title="DORA4" variant="fullHeight">
            <MyGroupSummaryDoraContent
              baEntity={baEntity}
              podEntity={podEntity}
              dfHelpHref={`${helpHrefBase}/df/`}
              ltfcHelpHref={`${helpHrefBase}/ltfc/`}
              cfrHelpHref={`${helpHrefBase}/cfr/`}
              mttrHelpHref={`${helpHrefBase}/mttr/`}
            />
          </InfoCard>
        </Grid>
        <Grid item xs={12} md={4} lg={6}>
          {(podEntity || baEntity) && (
            <EntityProvider entity={podEntity ?? baEntity}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <OwnershipCard
                    hideRelationsToggle
                    relationsType="aggregated"
                    groupBy="kind"
                  />
                </Grid>
                <Grid item>
                  <EntityTechInsightsOverviewCard />
                </Grid>
              </Grid>
            </EntityProvider>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
