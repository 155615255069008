import { EntityFilter } from '@backstage/plugin-catalog-react'
import { Entity } from '@backstage/catalog-model'

// TODO: Extract this into a shared module between backend and frontend
type TechInsightsSnapshot = {
  failedChecks: string[]
  passedChecks: string[]
}

export class EntityFailedChecksFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  filterEntity(entity: Entity): boolean {
    // The snapshots are delivered to metadata by the TechInsightsSnapshotProcessor
    const techInsightsSnapshot = entity.metadata.techInsightsSnapshot as
      | TechInsightsSnapshot
      | undefined
    return (techInsightsSnapshot?.failedChecks ?? []).some(checkId =>
      this.values.includes(checkId)
    )
  }

  toQueryValue(): string[] {
    return this.values
  }
}

export class EntityPassedChecksFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  filterEntity(entity: Entity): boolean {
    // The snapshots are delivered to metadata by the TechInsightsSnapshotProcessor
    const techInsightsSnapshot = entity.metadata.techInsightsSnapshot as
      | TechInsightsSnapshot
      | undefined
    return (techInsightsSnapshot?.passedChecks ?? []).some(checkId =>
      this.values.includes(checkId)
    )
  }

  toQueryValue(): string[] {
    return this.values
  }
}
