import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth
} from '@backstage/integration-react'
import { apiDocsConfigRef } from '@backstage/plugin-api-docs'
import { AnyApiFactory, configApiRef, createApiFactory } from '@backstage/core-plugin-api'
import ApiDocPluginFactory from './asyncapi'
import { DataTechRadarApi } from './custom/techRadar/techRadarApi'
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar'

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi)
  }),
  ScmAuth.createDefaultApiFactory(),
  // plugin-api-docs patch included in this app
  createApiFactory({
    api: apiDocsConfigRef,
    deps: {},
    factory: ApiDocPluginFactory
  }),
  createApiFactory(techRadarApiRef, new DataTechRadarApi())
]
