import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api'

import { rootRouteRef } from './routes'

export const datadogServiceCatalogPlugin = createPlugin({
  id: 'datadog-service-catalog',
  routes: {
    root: rootRouteRef
  }
})

export const DatadogServiceCatalogPage = datadogServiceCatalogPlugin.provide(
  createRoutableExtension({
    name: 'DatadogServiceCatalogPage',
    component: () => import('./components/Page').then(m => m.Page),
    mountPoint: rootRouteRef
  })
)

export const DatadogServiceCatalogLinksCard = datadogServiceCatalogPlugin.provide(
  createRoutableExtension({
    name: 'DatadogServiceCatalogLinksCard',
    component: () => import('./components/LinksCard').then(m => m.LinksCard),
    mountPoint: rootRouteRef
  })
)
