import React, { PropsWithChildren } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Progress } from '@backstage/core-components'
import { useApi, createApiRef } from '@backstage/core-plugin-api'
import { CatalogApi } from '@backstage/catalog-client'

const catalogApiRef = createApiRef({
  id: 'plugin.catalog.service'
})

/*
 * A wrapper around a direct child of Route component that makes silent
 * redirects if the entity described in the URL is missing.
 *
 * The `path` is assumed to be equal to the `path` of the surrounding Route
 * and expected to have :kind, :namespace, and :name parameters.
 *
 * The logic is the following: if there’s no exact match for kind:namespace/name
 * in the service catalog, try to find something with the same kind and name,
 * ignoring namespace completely. If found, the user is redirected to the
 * path related to this fuzzy-found entity.
 */
export function FuzzyRedirect({ path, children }: PropsWithChildren<{ path: string }>) {
  const catalogApi = useApi(catalogApiRef) as CatalogApi
  const navigate = useNavigate()
  const [isVerified, setIsVerified] = React.useState(false)

  const params = useParams()
  const { kind = '', namespace = '', name = '' } = params

  React.useEffect(() => {
    const verifyRoute = async () => {
      const directEntity = await catalogApi.getEntityByRef({ kind, namespace, name })
      if (directEntity) {
        return
      }

      const fuzzyEntities = await catalogApi.getEntities({
        filter: { kind: kind, 'metadata.name': name }
      })
      if (fuzzyEntities.items.length) {
        const fuzzyEntity = fuzzyEntities.items[0]
        const newPath = path
          .replace(':kind', kind)
          .replace(':namespace', fuzzyEntity.metadata.namespace ?? 'default')
          .replace(':name', name)
          .replace('*', params['*'] ?? '')

        navigate(newPath, { replace: true })
      }
    }

    verifyRoute()
      .then(() => setIsVerified(true))
      .catch(console.error)
  }, [kind, namespace, name])

  return isVerified ? <>{children}</> : <Progress />
}
