import { RadarEntry, RadarRing, RadarQuadrant } from './types'
import {
  TechRadarApi,
  TechRadarLoaderResponse
} from '@backstage-community/plugin-tech-radar'
import { RINGS, QUADRANTS } from './techRadarData'

const rings = new Array<RadarRing>()
for (const [_, { id, name, color }] of Object.entries(RINGS)) {
  rings.push({ id, name, color })
}

const quadrants = new Array<Omit<RadarQuadrant, 'entries'>>()
let entries = new Array<RadarEntry>()
for (const [_, { id, name, entries: quadrantEntries }] of Object.entries(QUADRANTS)) {
  quadrants.push({ id, name })
  entries = [
    ...entries,
    ...quadrantEntries.map((entry: RadarEntry) => ({ ...entry, quadrant: id }))
  ]
}

const data: TechRadarLoaderResponse = {
  entries,
  quadrants,
  rings
}

export class DataTechRadarApi implements TechRadarApi {
  async load() {
    return data
  }
}
