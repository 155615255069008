/**
 * This is the map of types for rendering.
 *
 * ComponentTemplateConfig maps entity.spec.type to broad types of components
 *
 * We plan to introduce more of these later.
 *
 * The goal is to make it easy to introduce a new type of component to refine the catalog.
 */
import { ConfigReader } from '@backstage/core-app-api'

export const ComponentTemplateConfig = new ConfigReader({
  Service: [
    'service',
    'lab',
    'bot',
    'script',
    'container-image',
    'iac',
    'backend-for-frontend'
  ],
  Website: ['website', 'microfrontend'],
  BackstageSystem: ['special', 'backstage-system']
})
