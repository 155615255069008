/**
 * This module is forked from the Backstage repository.
 *
 * Original Path: `@backstage/plugin-catalog-react/src/components/EntityKindPicker/kindFilterUtils.ts`
 *
 * Description:
 * - This module was modified to ensure that "entity" appears as the first item in `EntityKindPicker` with the label "All".
 *
 * Modifications:
 * - Added "entity: All" kind to `kindMap`
 * - `reduce` logic has been updated to construct `kindsMap` with "entity" as the first key-value pair.
 *
 * Purpose:
 * - These changes improve flexibility for `EntityKindPicker`.
 *
 * Maintainer Notes:
 * - For updates, consider pulling changes from the original Backstage module if needed and reapply the modifications.
 */

import { useApi } from '@backstage/core-plugin-api'

import { catalogApiRef } from '@backstage/plugin-catalog-react'
import useAsync from 'react-use/esm/useAsync'

import { createTranslationRef } from '@backstage/core-plugin-api/alpha'

export const catalogReactTranslationRef = createTranslationRef({
  id: 'catalog-react',
  messages: {
    catalogFilter: {
      title: 'Filters',
      buttonTitle: 'Filters'
    },
    entityKindPicker: {
      title: 'Kind',
      errorMessage: 'Failed to load entity kinds'
    },
    entityLifecyclePicker: {
      title: 'Lifecycle'
    },
    entityNamespacePicker: {
      title: 'Namespace'
    },
    entityOwnerPicker: {
      title: 'Owner'
    },
    entityProcessingStatusPicker: {
      title: 'Processing Status'
    },
    entityTagPicker: {
      title: 'Tags'
    },
    entityPeekAheadPopover: {
      title: 'Drill into the entity to see all of the tags.',
      emailCardAction: {
        title: 'Email {{email}}',
        subTitle: 'mailto {{email}}'
      },
      entityCardActionsTitle: 'Show details'
    },
    entitySearchBar: {
      placeholder: 'Search'
    },
    entityTypePicker: {
      title: 'Type',
      errorMessage: 'Failed to load entity types',
      optionAllTitle: 'all'
    },
    favoriteEntity: {
      addToFavorites: 'Add to favorites',
      removeFromFavorites: 'Remove from favorites'
    },
    inspectEntityDialog: {
      title: 'Entity Inspector',
      closeButtonTitle: 'Close',
      ancestryPage: {
        title: 'Ancestry'
      },
      colocatedPage: {
        title: 'Colocated',
        description:
          'These are the entities that are colocated with this entity - as in, they originated from the same data source (e.g. came from the same YAML file), or from the same origin (e.g. the originally registered URL).',
        alertNoLocation: 'Entity had no location information.',
        alertNoEntity: 'There were no other entities on this location.'
      },
      jsonPage: {
        title: 'Entity as JSON',
        description:
          'This is the raw entity data as received from the catalog, on JSON form.'
      },
      overviewPage: {
        title: 'Overview'
      },
      yamlPage: {
        title: 'Entity as YAML',
        description:
          'This is the raw entity data as received from the catalog, on YAML form.'
      }
    },
    unregisterEntityDialog: {
      title: 'Are you sure you want to unregister this entity?',
      cancelButtonTitle: 'Cancel',
      deleteButtonTitle: 'Delete Entity',
      deleteEntitySuccessMessage: 'Removed entity {{entityName}}',
      bootstrapState: {
        title:
          'You cannot unregister this entity, since it originates from a protected Backstage configuration (location "{{location}}"). If you believe this is in error, please contact the {{appTitle}} integrator.',
        advancedDescription:
          'You have the option to delete the entity itself from the catalog. Note that this should only be done if you know that the catalog file has been deleted at, or moved from, its origin location. If that is not the case, the entity will reappear shortly as the next refresh round is performed by the catalog.',
        advancedOptions: 'Advanced Options'
      },
      onlyDeleteStateTitle:
        'This entity does not seem to originate from a registered location. You therefore only have the option to delete it outright from the catalog.',
      unregisterState: {
        title: 'This action will unregister the following entities:',
        subTitle: 'Located at the following location:',
        description: 'To undo, just re-register the entity in {{appTitle}}.',
        unregisterButtonTitle: 'Unregister Location',
        advancedOptions: 'Advanced Options',
        advancedDescription:
          'You also have the option to delete the entity itself from the catalog. Note that this should only be done if you know that the catalog file has been deleted at, or moved from, its origin location. If that is not the case, the entity will reappear shortly as the next refresh round is performed by the catalog.'
      },
      errorStateTitle: 'Internal error: Unknown state'
    },
    userListPicker: {
      defaultOrgName: 'Company',
      personalFilter: {
        title: 'Personal',
        ownedLabel: 'Owned',
        starredLabel: 'Starred'
      },
      orgFilterAllLabel: 'All'
    }
  }
})

export function useAllKinds(): {
  loading: boolean
  error?: Error
  allKinds: string[]
} {
  const catalogApi = useApi(catalogApiRef)

  const {
    error,
    loading,
    value: allKinds
  } = useAsync(async () => {
    const items = await catalogApi
      .getEntityFacets({ facets: ['kind'] })
      .then(response => response.facets.kind?.map(f => f.value).sort() || [])
    return items
  }, [catalogApi])

  return { loading, error, allKinds: allKinds ?? [] }
}
export function filterKinds(
  allKinds: string[],
  allowedKinds?: string[],
  forcedKinds?: string
): Record<string, string> {
  let availableKinds = allKinds
  if (allowedKinds) {
    availableKinds = availableKinds.filter(k =>
      allowedKinds.some(
        a => a.toLocaleLowerCase('en-US') === k.toLocaleLowerCase('en-US')
      )
    )
  }
  if (
    forcedKinds &&
    !allKinds.some(
      a => a.toLocaleLowerCase('en-US') === forcedKinds.toLocaleLowerCase('en-US')
    )
  ) {
    availableKinds = availableKinds.concat([forcedKinds])
  }

  const kindsMap = {
    entity: 'All',
    ...availableKinds
      .filter(kind => kind !== 'entity')
      .sort()
      .reduce((acc, kind) => {
        acc[kind.toLocaleLowerCase('en-US')] = kind
        return acc
      }, {} as Record<string, string>)
  }

  return kindsMap
}
