import React from 'react'
import { makeStyles, Theme, Grid, List, Paper } from '@material-ui/core'

import { CatalogSearchResultListItem } from '@backstage/plugin-catalog'
import { TechDocsSearchResultListItem } from '@backstage/plugin-techdocs'
import { ConfluenceResultListItem } from '@k-phoen/backstage-plugin-confluence'

import { SearchType } from '@backstage/plugin-search'
import {
  DefaultResultListItem,
  SearchBar,
  SearchResult,
  SearchPagination
} from '@backstage/plugin-search-react'
import { Content, Header, Page } from '@backstage/core-components'

const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    padding: theme.spacing(1, 0)
  },
  filters: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5)
    }
  }
}))

const SearchPage = () => {
  const classes = useStyles()

  return (
    <Page themeId="home">
      <Header title="Search" />
      <Content>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Paper className={classes.bar}>
              <SearchBar />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <SearchType.Tabs
              types={[
                {
                  value: 'software-catalog',
                  name: 'Catalog'
                },
                {
                  value: 'techdocs',
                  name: 'Tech Docs'
                },
                {
                  value: 'confluence',
                  name: 'Confluence'
                }
              ]}
            />
          </Grid>
          <Grid item sm={12} md={8}>
            <SearchResult>
              {({ results }) => (
                <List>
                  {results.map(({ type, document, highlight, rank }) => {
                    switch (type) {
                      case 'software-catalog':
                        return (
                          <CatalogSearchResultListItem
                            key={document.location}
                            result={document}
                            highlight={highlight}
                            rank={rank}
                          />
                        )
                      case 'techdocs':
                        return (
                          <TechDocsSearchResultListItem
                            key={document.location}
                            result={document}
                            highlight={highlight}
                            rank={rank}
                          />
                        )
                      case 'confluence':
                        return (
                          <ConfluenceResultListItem
                            key={document.location}
                            result={document}
                            highlight={highlight}
                            rank={rank}
                          />
                        )
                      default:
                        return (
                          <DefaultResultListItem
                            key={document.location}
                            result={document}
                            highlight={highlight}
                            rank={rank}
                          />
                        )
                    }
                  })}
                </List>
              )}
            </SearchResult>
          </Grid>
          <Grid item xs={12}>
            <SearchPagination />
          </Grid>
        </Grid>
      </Content>
    </Page>
  )
}

export const searchPage = <SearchPage />
